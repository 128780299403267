import { connect, } from 'react-redux';
import { excludeShop, includeShop } from '../../../redux/reducers/searchShopsReducer';
import Button from '@mui/material/Button';

function ShopFilterButton({ shop, excludedShops , includeShop, excludeShop }) {

    function toggleButtonActive() {
        if (isShopActive()) {
            excludeShop(shop);
        } else {
            includeShop(shop);
        }
    }

    function isShopActive() {
        if (excludedShops) {
            return excludedShops.includes(shop);
        }
        return false;
    }

    return <Button
        variant={isShopActive() ? 'outlined' : 'contained'}
        // color={isShopActive() ? 'success' : 'error'}
        // style={{ backgroundColor: isShopActive() ? 'red' : 'green' }}
        onClick={() => toggleButtonActive()}
        type='button'>{shop.displayName}
    </Button>;
}

export default connect(null, {
    includeShop,
    excludeShop,
})(ShopFilterButton);