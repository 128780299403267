import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import translate from '../../../../translation/Translator';
import ExpenseRecordsTable from './ExpenseRecordsTable';

function ExpenseRecordsView() {

    return (
        // @ts-ignore
        <Container maxWidth='l'>
            <Box py={4}>
                <Typography variant='h4' component='h1' align='center' gutterBottom>
                    {translate('undefined', 'Existing records')}
                </Typography>
                <Box mb={2}>
                    <ExpenseRecordsTable />
                    {/* <Box display='flex' justifyContent='center' mt={2}> */}
                    {/*     <Button variant='contained' */}
                    {/*             color='primary' */}
                    {/*             onClick={handleSubmitExpenseRecords}> */}
                    {/*         {translate('unfedined', 'Submit Purchase Prices')} */}
                    {/*     </Button> */}
                    {/* </Box> */}
                </Box>
            </Box>
        </Container>
    );
}

export default ExpenseRecordsView;