import axios from 'axios';
import { API_URL } from './apiConstants';
import { getRequestConfigWithAuthToken } from './util';

export function updateUserCategories(category, notes) {
    return axios.put(
        `${API_URL}/user/category`,
        { category, notes },
        getRequestConfigWithAuthToken('put')
    );
}
