import axios from 'axios';
import {
    API_URL,
    CART_BIND,
    CART_BIND_APPROVE,
    CART_BIND_REJECT,
    USER,
    USER_ADD,
    USER_CART_ITEM,
    USER_CART_ITEMS,
    USER_CATEGORIES,
    USER_SEARCH,
} from './apiConstants';
import { TOKEN_STORAGE } from '../constants';
import { getRequestConfigWithAuthToken } from './util';

export async function getUserToken(user) {
    if (user && user.id) {
        const userRequest = {
            user: { id: user.id, email: user.email },
            lang: sessionStorage.getItem('i18nextLng'),
        };

        try {
            const response = await axios.post(API_URL + USER_ADD, userRequest);
            const token = response.data;
            sessionStorage.setItem(TOKEN_STORAGE, JSON.stringify(token));
        } catch (error) {
            //do nothing
        }
    }
    return null;
}

export function fetchUserDetails() {
    return axios.get(API_URL + USER, getRequestConfigWithAuthToken('get'));
}

export function saveUserSearchConfig({ categories, exclusions }) {
    saveUserCategories(categories);
    saveUserSearchItemExclusions(exclusions);
}

function saveUserCategories(categories) {
    return axios.post(
        API_URL + USER_CATEGORIES,
        { categories },
        getRequestConfigWithAuthToken('post'),
    );
}

function saveUserSearchItemExclusions(exclusions) {
    return axios.post(
        API_URL + USER_SEARCH,
        { exclusions },
        getRequestConfigWithAuthToken('post'),
    );
}

export function saveUserProductPriceNotes(productPriceNotes) {
    return axios.post(
        API_URL + '/user/productPriceNotes',
        { productPriceNotes },
        getRequestConfigWithAuthToken('post'),
    );
}

export function deleteCartItem(cartId, productId) {
    const payload = {
        ...getRequestConfigWithAuthToken('delete'),
        params: {
            productId,
            cartId,
        },
    };
    return axios.delete(API_URL + USER_CART_ITEM, payload);
}

export function addCartCartItem(cartId, productId) {
    return axios.post(
        API_URL + USER_CART_ITEM,
        { cartId, productId },
        getRequestConfigWithAuthToken('post'),
    );
}

export function addMultipleItemsToCart(cartId, productIdList) {
    return axios.post(
        API_URL + USER_CART_ITEMS,
        { cartId, productIdList },
        getRequestConfigWithAuthToken('post'),
    );
}

export function sendBindingRequest(otherUserId) {
    return axios.post(
        API_URL + CART_BIND,
        { otherUserId },
        getRequestConfigWithAuthToken('post'),
    );
}

export function rejectBindingRequest(otherUserId) {
    return axios.post(
        API_URL + CART_BIND_REJECT,
        { otherUserId },
        getRequestConfigWithAuthToken('post'),
    );
}

export function approveBindingRequest(otherUserId) {
    return axios.post(
        API_URL + CART_BIND_APPROVE,
        { otherUserId },
        getRequestConfigWithAuthToken('post'),
    );
}

export function logoutUser(user) {
    if (user && user.id) {
        const userRequest = { id: user.id };
        return axios.post(API_URL + USER_ADD, userRequest);
    }
    return null;
}
