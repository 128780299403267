import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { userExpenseRecordsSelector } from '../../redux/expenseRecordReducer';
import { useSelector } from 'react-redux';
import translate from '../../../../translation/Translator';
import dayjs from 'dayjs';
import { ExpenseRecord } from '../../../../types/ExpenseRecord';
import SavingsSummary_AggregateView from './SavingsSummary_AggregateView';
import { calculateSavingsForExpenseRecord } from '../../../../util/util';

function SavingsSummary() {
    // const [products, setProducts] = useState([]);
    const expenseRecords: ExpenseRecord[] = useSelector(userExpenseRecordsSelector);
    // const [currentDate, setCurrentDate] = useState();

    // const [selectedDate, setSelectedDate] = useState(dayjs());

    // function updateDateValue(newValue) {
    //     setSelectedDate(newValue);
    // }

    function resolveSavedAmount(expenseRecords: ExpenseRecord[]) {
        return expenseRecords.reduce((acc, product) => {
            const result = calculateSavingsForExpenseRecord(product);

            if (result > 0) {
                return acc + result;
            }

            return acc;
        }, 0);
    }

    return (
        // @ts-ignore
        <div>
            <Typography variant='h5' component='h2'>
                {translate('undefined', 'Savings')}
            </Typography>

            <Container>
                <Grid container spacing={0}>
                    {/* <Grid item> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                    {/*     <DateCalendar value={selectedDate} */}
                    {/*                   onChange={(newValue) => updateDateValue(newValue)} /> */}
                    {/* </LocalizationProvider> */}
                    {/* </Grid> */}

                    <Grid item alignContent='center'>
                        <SavingsSummary_AggregateView amount={resolveSavedAmount(expenseRecords)} />
                    </Grid>


                </Grid>
            </Container>
        </div>
    );
}

export default SavingsSummary;
