import { actionTypes } from '../actions/actionTypes';
import { productSelectorReducer } from './searchProductsReducer';
import { authReducer } from './authReducer';
import { cartReducer } from './cartReducer';
import { userCategoriesReducer } from './userCategoriesReducer';
import { bindingRequestReducer } from './bindingRequestReducer';
import { userNotesReducer } from './userNoteReducer';
import { userItemExclusionsReducer } from './userItemExclusionsReducer';
import searchShopsReducer from './searchShopsReducer';

export const appErrorReducer = (appError = false, { type }) => {
    if (type === actionTypes.API_ERROR) {
        return true;
    }
    return appError;
};

export const productReducer = (state = { products: {} }, { type, payload }) => {
    switch (type) {
        case actionTypes.PRODUCT_LOAD_START:
            return { ...state, isFetching: true, errorMsg: null };
        case actionTypes.PRODUCT_LOAD_SUCCESS:
            return { ...state, isFetching: false, products: payload };
        case actionTypes.PRODUCT_LOAD_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMsg: 'Unexpected api error',
            };
        case actionTypes.PRODUCT_UPDATE:
            return { ...state, products: payload };
        case actionTypes.PRODUCT_ADD:
            const ps = { ...state.products };
            const newArray = [...(ps[payload.key] || []), ...payload.data];
            ps[payload.key] = newArray;
            return { ...state, products: ps };
        case actionTypes.FILTER_PRODUCT_CATEGORY:
            const stateProducts = { ...state.products };
            stateProducts[payload.category] = payload.filteredProductList;
            return { ...state, products: stateProducts };
        case actionTypes.PRODUCT_ADD_CATEGORIES_WITH_PRODUCTS:
            return { ...state, products: { ...state.products, ...payload } };
        default:
            return state;
    }
};

export const searchReducer = (state = '', { type, payload }) => {
    switch (type) {
        case actionTypes.QUERY_UPDATE:
            return payload;
        default:
            return state;
    }
};

export default {
    productReducer,
    searchReducer,
    appErrorReducer,
    cartReducer,
    productSelectorReducer,
    authReducer,
    userCategoriesReducer,
    userNotesReducer,
    bindingRequestReducer,
    userItemExclusionsReducer,
    searchShopsReducer
};
