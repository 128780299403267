import { useState } from 'react';

function ImageWithMagnifier({ url }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    const magnifierHeight = 150;
    const magnifierWidth = 200;
    const zoomLevel = 2;

    return (
        <div className="main_product_img_wrapper">
            <img
                className="base_image"
                src={url}
                onError={({ currentTarget }) => {
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.onerror = null; // prevents looping
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.src = '/placeholder.png';
                }}
                alt="Product"
                onMouseEnter={(e) => {
                    // update image size and turn-on magnifier
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                }}
                onMouseMove={(e) => {
                    // update cursor position
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();

                    // calculate cursor position on the image
                    const xNew = e.pageX - left - window.pageXOffset;
                    const yNew = e.pageY - top - window.pageYOffset;
                    setXY([xNew, yNew]);
                }}
                onMouseLeave={() => {
                    // close magnifier
                    setShowMagnifier(false);
                }}
            />
            <div
                style={{
                    display: showMagnifier ? '' : 'none',
                    position: 'absolute',

                    // prevent magnifier blocks the mousemove event of img
                    pointerEvents: 'none',
                    // set size of magnifier
                    height: `${magnifierHeight}px`,
                    width: `${magnifierWidth}px`,
                    // move element center to cursor pos
                    top: `${y - magnifierHeight / 2}px`,
                    left: `${x - magnifierWidth / 2}px`,
                    opacity: '1', // reduce opacity so you can verify position
                    border: '1px solid lightgray',
                    backgroundColor: 'white',
                    backgroundImage: `url('${url}')`,
                    backgroundRepeat: 'no-repeat',

                    // calculate zoomed image size
                    backgroundSize: `${imgWidth * zoomLevel}px ${
                        imgHeight * zoomLevel
                    }px`,

                    // calculate position of zoomed image.
                    backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                    backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                }}
            />
        </div>
    );
}

export default ImageWithMagnifier;
