import React, { useEffect, useState } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import translate from '../../../../translation/Translator';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { replaceNonCharsWithEmpty } from '../../../../util/inputUtil';
import {
    addProductPriceNotesWithApi,
    productPriceNotesSelector,
} from '../../../savings/redux/productPriceNotesReducer';
import { connect, useSelector } from 'react-redux';
import { CategoryPriceNotes } from '../../../../types/CategoryPriceNotes';

function ItemPricingConfig({ items, activeItem, category, addProductPriceNotesWithApi }) {
    const categoryPriceNotes: CategoryPriceNotes = useSelector(productPriceNotesSelector);
    useEffect(() => {
        const newItemName = items[activeItem];
        setItemName(newItemName);

        const priceNote = categoryPriceNotes?.[category]?.[newItemName];

        if (priceNote?.regularPrice) {
            setRegularPrice(priceNote.regularPrice);
        }

        if (categoryPriceNotes && !priceNote?.regularPrice) {
            setRegularPrice('');
        }

    }, [activeItem, items]);

    const [regularPrice, setRegularPrice] = useState<number | string>('');
    const [itemName, setItemName] = useState<string>('');

    const onDeleteClick = () => {
        setRegularPrice('');
    };

    const onRegularPriceChange = (event) => {
        setRegularPrice(replaceNonCharsWithEmpty(event.target.value));
    };

    const onConfirmClick = () => {
        if (regularPrice == '') {
            return;
        }

        // @ts-ignore
        addProductPriceNotesWithApi({ category, itemName, regularPrice });
    };

    return <Box mt={2} mb={7} display={'absolute'} alignItems='center' justifyContent='center'>
        <Typography sx={{ padding: '10px' }} variant='h6'
                    component='h4'>{translate('undefined', 'Regular price per kg or l')}</Typography>

        <Box display='flex' justifyContent='center' alignItems='center'>
            <Typography sx={{ padding: '10px' }} variant='h5'>{itemName}</Typography>

            <TextField
                variant='outlined'
                sx={{ marginLeft: '5px' }}
                value={regularPrice}
                onChange={(event) =>
                    onRegularPriceChange(event)
                }
                InputProps={{
                    startAdornment: (
                        <IconButton size='large' onClick={() => onDeleteClick()}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                    endAdornment: (
                        <IconButton size='large' onClick={() => onConfirmClick()}>
                            <DoneIcon fontSize='small' />
                        </IconButton>
                    ),
                }}
            />
        </Box>
    </Box>;
}

export default connect(null, { addProductPriceNotesWithApi })(ItemPricingConfig);
