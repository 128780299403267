import React from 'react';
import { connect } from 'react-redux';
import ProductList from './ProductList/ProductList';
import { updateDataAction } from '../../redux/actions';
import { filterProductsByExclusions } from '../../util/util';

class ProductSection extends React.Component {
    generateProductLists() {
        const { products, notes, exclusions } = this.props;
        const keys = Object.keys(products);
        return keys.map((key) => (
            <ProductList
                key={key}
                productType={key}
                products={filterProductsByExclusions(products[key], exclusions[key])}
                notes={key && (notes[key] || [])}
            />
        ));
    }

    renderNotFoundProducts() {
        const { products, store } = this.props;
        const keys = Object.keys(products).map((k) => k.toLowerCase());
        const selectedItems = Object.values(
            store.getState().productSelectorReducer,
        ).flat(); // get items, that was selected
        const writtenItems = store.getState().searchReducer.split(',');
        // merge text input and buttons
        const notFoundItems = [...selectedItems, ...writtenItems].filter(
            (key) => !keys.includes(key.toLowerCase()) && key !== '',
        );

        if (notFoundItems.length > 0) {
            return (
                <p>
                    Not found discounts: <span>{notFoundItems.join(', ')}</span>
                </p>
            );
        }
        return null;
    }

    render() {
        const { isFetching } = this.props;
        if (!isFetching) {
            return (
                <>
                    <div className='notFoundProducts'>
                        {this.renderNotFoundProducts()}
                    </div>

                    {this.generateProductLists()}
                </>
            );
        }
        return (
            <div className='loader-wrapper'>
                <div className='loader' />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    products: state.productReducer.products,
    exclusions: state.userItemExclusionsReducer,
    isFetching: state.productReducer.isFetching,
    notes: state.userNotesReducer,
});

export default connect(mapStateToProps, { updateDataAction })(ProductSection);
