import { actionTypes } from './actionTypes';
import {
    addCartCartItem,
    approveBindingRequest,
    deleteCartItem,
    fetchUserDetails,
    sendBindingRequest,
} from '../../api/user';
import { updateUserCategories } from '../../api/userCategoryItems';
import { IS_PRODUCTION, MAX_CHARS_PER_NOTE } from '../../constants';

// eslint-disable-next-line import/no-cycle
import { loadDataForProducts } from '../../api/productData';
import itemSearchExclusionsActions from './itemSearchExclusions/itemExclusionsActions';
import UserDataApiMock from '../../api/mock/userDataApiMock';
import { loadUserExpenseRecords } from '../../pages/savings/redux/expenseRecordReducer';
import { loadProductPriceNotes } from '../../pages/savings/redux/productPriceNotesReducer';

export const setAppError = () => ({
    type: actionTypes.API_ERROR,
});


export const updateQueryAction = (query) => ({
    type: actionTypes.QUERY_UPDATE,
    payload: query,
});

// TILBUD API

export const dataLoadStart = () => ({
    type: actionTypes.PRODUCT_LOAD_START,
});

export const dataLoadSuccess = (data) => ({
    type: actionTypes.PRODUCT_LOAD_SUCCESS,
    payload: data,
});

export const dataLoadError = (errorMsg) => ({
    type: actionTypes.PRODUCT_LOAD_ERROR,
    payload: errorMsg,
});

export const updateDataAction = (data) => ({
    type: actionTypes.PRODUCT_UPDATE,
    payload: data,
});

export const addSingleProductAction = (key, data) => ({
    type: actionTypes.PRODUCT_ADD,
    payload: { key, data },
});
export const addProductsWithCategories = (data) => ({
    type: actionTypes.PRODUCT_ADD_CATEGORIES_WITH_PRODUCTS,
    payload: data,
});

export const filterProductCategoryAction = (category, filteredProductList) => ({
    type: actionTypes.FILTER_PRODUCT_CATEGORY,
    payload: { category, filteredProductList },
});

export const fetchDataAction = (productList, excludedShops) =>
// TODO check here
    function(dispatch) {
        dispatch(dataLoadStart());
        loadDataForProducts(productList, excludedShops.map((shop) => shop.searchName), dispatch);
    };

export const selectProductsAction = (category, sources) => ({
    type: actionTypes.PRODUCT_SELECT,
    payload: { sources, category },
});

export const deSelectProductsAction = (category, sources) => ({
    type: actionTypes.PRODUCT_DESELECT,
    payload: { sources, category },
});

export const googleSignIn = (userId) => ({
    type: actionTypes.SIGN_IN,
    payload: userId,
});
export const googleSignOut = () => ({
    type: actionTypes.SIGN_OUT,
});

export const loadUserCategories = (categories) => ({
    type: actionTypes.LOAD_USER_CATEGORIES,
    payload: categories,
});

export const removeUserCategoryItem = (category, item) => ({
    type: actionTypes.REMOVE_USER_CATEGORY_ITEM,
    payload: { category, item },
});

export const addUserCategoryItem = (category, item) => ({
    type: actionTypes.ADD_USER_CATEGORY_ITEM,
    payload: { category, item },
});

export const addUserCategory = (category) => ({
    type: actionTypes.ADD_USER_CATEGORY,
    payload: category,
});
export const removeUserCategory = (category) => ({
    type: actionTypes.REMOVE_USER_CATEGORY,
    payload: category,
});

export const setUserCategories = (categories) => ({
    type: actionTypes.SET_USER_CATEGORIES,
    payload: categories,
});

export const loadUserCart = (products) => ({
    type: actionTypes.LOAD_USER_CART,
    payload: products,
});

export const setCartBindingRequest = (bindingRequest) => ({
    type: actionTypes.SET_BINDING_REQUEST,
    payload: bindingRequest,
});

export const deleteCartBindingRequest = (otherUserId) => ({
    type: actionTypes.DELETE_BINDING_REQUEST,
    payload: otherUserId,
});

export const sendCartBindingRequest = (otherUserId) =>
    function(dispatch) {
        sendBindingRequest(otherUserId).then((response) => {
            dispatch(setCartBindingRequest(response.data));
        });
    };
export const approveCartBindingRequest = (otherUserId) =>
    function(dispatch) {
        approveBindingRequest(otherUserId).then((response) => {
            dispatch(setCartBindingRequest(response.data));
        });
    };

export const loadUserNotes = (notes) => ({
    type: actionTypes.LOAD_USER_NOTES,
    payload: notes,
});

export const fetchUserDataAction = () =>
    function(dispatch) {
        fetchUserDetails().then((response) => {
            dispatch(loadUserCategories(response.data.categories));
            dispatch(loadUserCart(response.data.cart));
            dispatch(loadUserNotes(response.data.categoryNotes));
            dispatch(loadProductPriceNotes(response.data.productPriceNotes));
            dispatch(loadUserExpenseRecords(response.data.expenseRecords));
            dispatch(itemSearchExclusionsActions.load(response.data.itemSearchExclusions));
            dispatch(setCartBindingRequest(response.data.bindingRequest));
        }).catch(() => {
            if (!IS_PRODUCTION) {
                const apiMock = new UserDataApiMock();
                dispatch(loadUserCategories(apiMock.getCategories()));
                dispatch(loadUserCart(apiMock.getCart()));
                dispatch(loadUserNotes(apiMock.getCategoryTextNotes()));
                dispatch(loadProductPriceNotes(apiMock.getProductPriceNotes()));
                dispatch(loadUserExpenseRecords(apiMock.getExpenseRecords()));
                dispatch(itemSearchExclusionsActions.load(apiMock.getItemSearchExclusions()));
                dispatch(setCartBindingRequest(apiMock.getBindingRequest()));
            }
        });
    };

export const addProductToBasket = (cartId, product) => ({
    type: actionTypes.ADD_PRODUCT_TO_SHOPPING_BASKET,
    payload: { cartId, product },
});

export const addProductToBasketAction = (cartId, product) =>
    function(dispatch, getState) {
        const isSigned = getState().authReducer.isSignedIn;
        if (isSigned) {
            addCartCartItem(cartId, product.id);
        }
        dispatch(addProductToBasket(cartId, product));
    };

export const removeProductFromBasket = (cartId, product) => ({
    type: actionTypes.REMOVE_PRODUCT_FROM_SHOPPING_BASKET,
    payload: { cartId, product },
});

export const removeProductFromBasketAction = (cartId, product) =>
    function(dispatch, getState) {
        const isSigned = getState().authReducer.isSignedIn;
        if (isSigned) {
            deleteCartItem(cartId, product.id);
        }
        dispatch(removeProductFromBasket(cartId, product));
    };

// CATEGORY NOTES
// ADD_NOTE_TO_CATEGORY: 'ADD_NOTE_TO_CATEGORY',
//     DELETE_NOTE_FROM_CATEGORY: 'DELETE_NOTE_FROM_CATEGORY',

export const updateCategoryNotes = (category, notes) => ({
    type: actionTypes.UPDATE_CATEGORY_NOTES,
    payload: { category, notes },
});

export const addNoteToCategoryAction = (category, note) => ({
    type: actionTypes.ADD_NOTE_TO_CATEGORY,
    payload: { category, note },
});

export const updateUserCategoryNoteAction = (category, previousNote, text) =>
    function(dispatch, getState) {
        const allNotes = getState().userNotesReducer[category];
        const result = [...allNotes];
        const indexOf = allNotes.indexOf(previousNote);
        const isNoteRepeated = allNotes.indexOf(text) >= 0;
        if (
            indexOf >= 0 &&
            !isNoteRepeated &&
            text.length <= MAX_CHARS_PER_NOTE
        ) {
            result[indexOf] = text;
        }

        updateUserCategories(category, result)
            .then(() => {
                dispatch(updateCategoryNotes(category, result));
            })
            .catch(() => {
                if (!process.env.REACT_APP_DOCKERIZED) {
                    dispatch(updateCategoryNotes(category, result));
                }
            });
    };

export const deleteUserCategoryNoteAction = (category, index) =>
    function(dispatch, getState) {
        const allNotes = getState().userNotesReducer[category];
        const filteredNotes = allNotes.filter((item, i) => i !== index);

        updateUserCategories(category, filteredNotes)
            .then(() => {
                dispatch(updateCategoryNotes(category, filteredNotes));
            })
            .catch(() => {
                if (!process.env.REACT_APP_DOCKERIZED) {
                    dispatch(updateCategoryNotes(category, filteredNotes));
                }
            });
    };
