import React from 'react';
import './ShoppingListSection.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShoppingList from './ShoppingList/ShoppingList';
import { Product } from '../../types/Product';

interface MappedItemsByShop {
    [key: string]: Product[];
}

function CartSection({ items }: { items: Product[] }) {
    const { t } = useTranslation();
    const mapProductsByShop = (productList): MappedItemsByShop =>
        productList.reduce((start, next) => {
            // eslint-disable-next-line no-param-reassign
            start[next.shop] = [...(start[next.shop] || []), next];
            return start;
        }, {});

    const renderShoppingItems = () => {
        const shoppingObject = mapProductsByShop(items);
        return Object.keys(shoppingObject)
            .map((shopNameAsKey) => (
                <React.Fragment key={shopNameAsKey}>
                    <h2 className='shop-heading'>{shopNameAsKey}</h2>
                    <ShoppingList shoppingItems={shoppingObject[shopNameAsKey]} />
                </React.Fragment>
            ));
    };

    if (items && items.length !== 0) {
        return <div className='ShoppingListSection'>{renderShoppingItems()}</div>;
    }
    return (
        <div className='noItemsFoundMessage'>
            <h2>{t('cart.noProductsSelected', 'No products was selected...')}</h2>
            <Link to='/'>{t('cart.goBack', 'Go back')}</Link>
        </div>
    );
}

const mapStateToProps = (state): { items: Product[] } => ({
    items: state.cartReducer.products,
});
export default connect(mapStateToProps)(CartSection);
