import React from 'react';
import './ProductCategory.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    addUserCategoryItem,
    deSelectProductsAction,
    removeUserCategoryItem,
    selectProductsAction,
} from '../../../redux/actions';
import InputWithBtn from '../InputWithBtn';

class ProductCategory extends React.Component {
    isBtnActive(element) {
        const { selectedProducts } = this.props;
        if (selectedProducts) {
            return selectedProducts.includes(element);
        }
        return false;
    }

    toggleOption(el) {
        const {
            deSelectProductsAction: deselectProduct,
            selectProductsAction: selectProduct,
            category,
        } = this.props;

        if (this.isBtnActive(el)) {
            deselectProduct(category, el);
        } else {
            selectProduct(category, el);
        }
    }

    addCategory(item) {
        const { category, addUserCategoryItem: addCategoryItem } = this.props;
        addCategoryItem(category, item);
    }

    renderOptions(data, category, areEditable) {
        const { removeUserCategoryItem: removeCategoryItem } = this.props;
        return data.map((el) => (
            <div key={el} className={areEditable ? 'removeItemContainer' : ''}>
                <button
                    type="button"
                    key={el}
                    className={
                        this.isBtnActive(el) && !areEditable ? 'selectedBtn' : ''
                    }
                    onClick={!areEditable ? () => this.toggleOption(el) : null}
                >
                    {el}
                </button>
                {areEditable ? (
                    <button
                        key={`${el}-delete`}
                        className="categoryItemDeleteBtn"
                        type="button"
                        onClick={() => removeCategoryItem(category, el)}
                    >
                  X
                    </button>
                ) : null}
            </div>
        ));
    }

    render() {
        const { index, products, category, editable } = this.props;
        const { t } = this.props;
        return (
            <>
                {editable ? (
                    <div id="itemAddSection" className="margin-centered">
                        <h3 className="categoryEditLabel">
                            {t('menu.newSearchItem', 'New search item')}
                        </h3>
                        <InputWithBtn
                            clicked={(item) => this.addCategory(item)}
                            placeholder={t(
                                'menu.newSearchItemEg',
                                'e.g. Skrabeæg = æg'
                            )}
                        />
                    </div>
                ) : (
                    ''
                )}
                <div
                    className="product-category"
                    role="tabpanel"
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                >
                    {products
                        ? this.renderOptions(products, category, editable)
                        : ''}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedProducts: state.productSelectorReducer[ownProps.category],
});

export default connect(mapStateToProps, {
    selectProductsAction,
    deSelectProductsAction,
    removeUserCategoryItem,
    addUserCategoryItem,
})(withTranslation()(ProductCategory));
