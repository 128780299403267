import { actionTypes } from '../actions/actionTypes';
import { approveBindingRequest, rejectBindingRequest } from '../../api/user';

export const bindingRequestReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_BINDING_REQUEST:
            return payload || state;
        case actionTypes.DELETE_BINDING_REQUEST:
            rejectBindingRequest(payload);
            return {};
        case actionTypes.APPROVE_BINDING_REQUEST:
            approveBindingRequest(payload);
            return { ...payload, isApproved: true };
        default:
            return state;
    }
};
