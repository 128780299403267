import React, { useState } from 'react';
import editIcon from '../../../assets/edit.svg';
import tickIcon from '../../../assets/tick.svg';
import deleteIcon from '../../../assets/delete.svg';
import './CheapestViewItem.scss';
import { MAX_CHARS_PER_NOTE } from '../../../constants';

function CheapestViewItem({ idx, note, onDeleteClick, saveNote }) {
    const [isEditing, setIsEditing] = useState(note === '');
    const [inputText, setInputText] = useState(note);
    const previousNote = note;
    const onInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleTextSubmit = (event) => {
        event.preventDefault();
        saveNote(previousNote, inputText);
    };

    function onEditIconClick() {
        setIsEditing(!isEditing);
    }

    function deleteHandler() {
        onDeleteClick(idx);
    }

    function renderComponentWithoutEditing() {
        return <h5>{inputText}</h5>;
    }

    function renderComponentWithEditing() {
        return (
            <form onSubmit={handleTextSubmit}>
                <input
                    type="text"
                    placeholder="Write note"
                    onChange={onInputChange}
                    value={inputText}
                    maxLength={MAX_CHARS_PER_NOTE}
                />
                <button type="submit">
                    <img src={tickIcon} alt="tick" />
                </button>
            </form>
        );
    }

    const component = isEditing
        ? renderComponentWithEditing()
        : renderComponentWithoutEditing();

    return (
        <div className="cheapest_view_item">
            {component}
            {!isEditing && (
                <>
                    <button
                        className="edit_icon"
                        type="button"
                        onClick={onEditIconClick}
                    >
                        <img src={editIcon} alt="edit_icon" />
                    </button>
                    <button
                        className="delete-icon"
                        type="button"
                        onClick={deleteHandler}
                    >
                        <img src={deleteIcon} alt="edit_icon" />
                    </button>
                </>
            )}
        </div>
    );
}

export default CheapestViewItem;
