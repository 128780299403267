import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addUserCategory, setUserCategories } from '../../../../redux/actions';
import SearchConfigConfirmSection from './SearchConfigConfirmSection';
import SearchConfigGroupingSection from './SearchConfigGroupingSection';
import SearchConfigItemSection from './SearchConfigItemSection';
import SearchConfigExclusionsSection from './SearchConfigExclusionsSection';

function SearchConfigView({
    categories,
    itemSearchExclusions,
}) {

    const [activeTab, setActiveTab] = useState(0);
    const [activeItem, setActiveItem] = useState(0);
    const [activeKeyName, setActiveKeyName] = useState();
    const categoriesKeys = Object.keys(categories);
    const categoryToRender = categoriesKeys[activeTab];

    useEffect(() => {
        if (categories[categoryToRender] && categories[categoryToRender][activeItem]) {
            setActiveKeyName(categories[categoryToRender][activeItem]);
        }
    }, [activeItem, categories, activeTab]);

    return (
        <div className='alterCategoriesPart'>
            <SearchConfigGroupingSection data={categories}
                onItemClick={setActiveTab}
                activeTab={activeTab} />

            <SearchConfigItemSection data={categories[categoryToRender]}
                categoryName={categoryToRender}
                activeItem={activeItem}
                clicked={setActiveItem}
                childData={itemSearchExclusions}
                setActiveKeyName={setActiveKeyName} />

            <SearchConfigExclusionsSection data={itemSearchExclusions}
                activeItem={activeItem}
                categories={categories}
                activeKeyName={activeKeyName} />

            <SearchConfigConfirmSection
                categories={categories}
                exclusions={itemSearchExclusions}/>

        </div>
    );
}

SearchConfigView.propTypes = {};

const mapStateToProps = (state) => ({
    categories: state.userCategoriesReducer,
    itemSearchExclusions: state.userItemExclusionsReducer,
});
export default connect(mapStateToProps, {
    addUserCategory,
    setUserCategories,
})(SearchConfigView);
