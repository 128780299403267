import React from 'react';
import { connect } from 'react-redux';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ItemsView from './ItemsView';
import SubmitTextInput from './SubmitTextInput';
import itemSearchExclusionsActions from '../../../../redux/actions/itemSearchExclusions/itemExclusionsActions';

function SearchConfigExclusionsSection({
    data,
    addItemSearchExclusion: addExclusion,
    deleteItemSearchExclusion: deleteExclusion,
    activeKeyName,
}) {
    const { t } = useTranslation();

    return (
        <div className='alterExclusionsPart'>
            <Typography sx={{ mt: 2 }} variant='h5'>
                {t('menu.excludedFromSearch', 'Excluded from search for item')}
            </Typography>
            <SubmitTextInput
                name='Grouping'
                placeholder={t('menu.newExclusionExample', 'e.g = pal -> exclude from æg')}
                clicked={(text) => addExclusion(activeKeyName, text)}
                validation={{
                    msg: t(
                        'validation.inputIsTooShort',
                        'Input is too short',
                    ),
                    isValid: text => text.length > 2,
                }} />
            <ItemsView data={data[activeKeyName]}
            // category={categoryToRender}
                onDelete={(a) => deleteExclusion(activeKeyName, a)}
            />
        </div>
    );
}

export default connect(null, {
    addItemSearchExclusion: itemSearchExclusionsActions.add,
    deleteItemSearchExclusion: itemSearchExclusionsActions.delete,
})(SearchConfigExclusionsSection);
