import * as React from 'react';
import './AddExpenseDialog.scss';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Slider,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import translate from '../../../translation/Translator';
import { connect, useSelector } from 'react-redux';
import { dateToString, deduceGroupingFromCategoryName } from '../../../util/util';
import { CategoryPriceNotes } from '../../../types/CategoryPriceNotes';
import {
    AddProductPriceNote,
    addProductPriceNotesWithApi,
    productPriceNotesSelector,
} from '../../savings/redux/productPriceNotesReducer';
import { PriceNoteType } from '../../../types/PriceNoteType';
import { styled } from '@mui/material/styles';
import { addUserExpenseRecordWithApi } from '../../savings/redux/expenseRecordReducer';
import { ExpenseRecord } from '../../../types/ExpenseRecord';

const maxSavedPricingValues = 300;

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
    {
        value: 150,
        label: '150',
    },
    {
        value: 200,
        label: '200',
    },
    {
        value: maxSavedPricingValues,
        label: `${maxSavedPricingValues}`,
    },
];

const marksKg = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 250,
        label: '250',
    },
    {
        value: 500,
        label: '500',
    },
    {
        value: 750,
        label: '750',
    },
    {
        value: 1000,
        label: '1000',
    },
];

const Separator = styled('div')(
    ({ theme }) => `
  height: ${theme.spacing(3)};
`,
);
const Line = styled('div')(
    ({ theme }) => `
    height: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.palette.divider};
`,
);

function AddExpenseDialog(props) {
    const {
        searchItem,
        categories,
        product,
        closeExpenseDialog,
        addUserExpenseRecordWithApi,
        addProductPriceNotesWithApi,
    } = props;
    const category = deduceGroupingFromCategoryName(categories, searchItem);
    const categoryPriceNotes: CategoryPriceNotes = useSelector(productPriceNotesSelector);
    const theme = useTheme();
    const useFullWidth = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        setRegularPrice(resolveRegularPrice());
    }, [categoryPriceNotes]);


    const priceNote = getCategoryPriceNoteElement();

    function resolveRegularPrice() {
        return priceNote ? priceNote.regularPrice : 0;
    }

    const [regularPrice, setRegularPrice] = React.useState(resolveRegularPrice());
    const [purchasePrice, setPurchasePrice] = React.useState(product.price);
    const [purchaseVolume, setPurchaseVolume] = React.useState(1000);
    const [saveRegularPriceCheckMark, setSaveRegularPriceCheckMark] = React.useState(false);

    function onCheckMarkChange(event) {
        setSaveRegularPriceCheckMark(event.target.checked);
    }

    function getCategoryPriceNoteElement(): PriceNoteType {
        if (categoryPriceNotes[category]) {
            return categoryPriceNotes[category][searchItem];
        }

        return null;
    }

    const onAddExpense = () => {
        const recordedPurchase: ExpenseRecord = {
            category,
            categoryItem: searchItem,
            regularPrice,
            purchasePrice,
            purchaseVolume,
            createdAt: dateToString(new Date()),
        };


        if (saveRegularPriceCheckMark === true) {
            const addPriceNoteRequest: AddProductPriceNote = {
                category, itemName: searchItem, regularPrice,
            };
            addProductPriceNotesWithApi(addPriceNoteRequest);
        }

        addUserExpenseRecordWithApi(recordedPurchase);
        closeExpenseDialog();
    };


    function handleVolumeChange(e) {
        setPurchaseVolume(e.target.value);
    }

    function handlePurchasePriceChange(e) {
        setPurchasePrice(e.target.value);
    }

    return <div className='add-expense-dialog'>
        {
            product &&
            (<Dialog fullWidth={true} maxWidth={useFullWidth ? 'xl' : 'md'} open={true} onClose={closeExpenseDialog}>
                    <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
                        {translate('savings.addExpense', 'Add expense')}
                    </DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={closeExpenseDialog}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <div className='dialog-title'>
                            <h2>{category} - {searchItem}</h2>
                        </div>

                        <Separator />

                        <div id='regular-price-sugestion-block'
                             className='suggestion-track'>
                            <Stack direction='row' gap={2}>
                                <TextField
                                    className='newProductForm__input'
                                    label={translate('savings.regularPrice', 'Saved regular price')}
                                    type='number'
                                    value={regularPrice == 0 ? '' : regularPrice}
                                    onChange={(event) => {
                                        const newValue = parseInt(event.target.value);
                                        if (newValue > 1000) {
                                            setRegularPrice(1000);
                                            return;
                                        } else if (Number.isNaN(newValue)) {
                                            setRegularPrice(0);
                                            return;
                                        }
                                        setRegularPrice(newValue);
                                    }
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: '0' }}
                                />
                                <Slider
                                    aria-label='Always visible'
                                    step={1}
                                    min={1}
                                    max={maxSavedPricingValues}
                                    marks={marks}
                                    valueLabelDisplay='on'
                                    value={regularPrice}
                                    onChange={(_event, value: number) => setRegularPrice(value)}
                                />
                            </Stack>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox
                                        value={saveRegularPriceCheckMark}
                                        onChange={(e) => onCheckMarkChange(e)} />
                                    }
                                    label={translate('undefined', 'Save regular price')} />
                            </div>
                        </div>

                        <Line />
                        <Separator />


                        <div id='purchase-price-sugestion-block'
                             className='suggestion-track'>
                            <Typography id='track-inverted-slider' gutterBottom>
                                {translate('undefined', 'Record purchase')}
                            </Typography>

                            <Stack direction='row' gap={3}>

                                <Slider
                                    aria-label='Always visible'
                                    value={purchaseVolume}
                                    step={1}
                                    min={1}
                                    max={1000}
                                    marks={marksKg}
                                    onChange={(_event, value: number) => setPurchaseVolume(value)}
                                    valueLabelDisplay='on'
                                />

                                <TextField
                                    className='newProductForm__input'
                                    label={translate('undefined', 'Volume')}
                                    type='number'
                                    value={purchaseVolume}
                                    onChange={handleVolumeChange}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: '0' }}
                                />

                            </Stack>

                            <Separator />

                            <Stack direction='row' gap={3}>

                                <Slider
                                    aria-label='Always visible'
                                    value={purchasePrice}
                                    step={1}
                                    min={1}
                                    max={maxSavedPricingValues}
                                    marks={marks}
                                    onChange={(_event, value: number) => setPurchasePrice(value)}
                                    valueLabelDisplay='on'
                                />

                                <TextField
                                    className='newProductForm__input'
                                    label={translate('savings.purchasePrice', 'Purchase price')}
                                    type='number'
                                    value={purchasePrice}
                                    onChange={handlePurchasePriceChange}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: '0' }}
                                />
                            </Stack>

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onAddExpense}><AddIcon fontSize='large' /></Button>
                    </DialogActions>
                </Dialog>
            )
        }
    </div>;
}


export default connect(null, { addUserExpenseRecordWithApi, addProductPriceNotesWithApi })
(AddExpenseDialog);

