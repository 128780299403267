import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addUserCategoryItem, removeUserCategoryItem } from '../../../../redux/actions';
import SubmitTextInput from './SubmitTextInput';
import ItemPricingConfig from './ItemPricingConfig';
import ItemsView from './ItemsView';


function SearchConfigItemSection({
    data,
    clicked,
    activeItem,
    childData,
    categoryName,
    addUserCategoryItem: addItem,
    removeUserCategoryItem: removeItem,
}) {
    if (!data) {
        return null;
    }
    const { t } = useTranslation();

    return (
        <div className='alterCategoriesPart'>
            <Typography sx={{ mt: 2 }} variant='h5'>
                {t('menu.searchItems', 'Search items')}
            </Typography>
            <SubmitTextInput
                name='Grouping'
                placeholder={t('menu.newSearchItemEg', 'e.g. Skrabeæg = æg')}
                clicked={(text) => addItem(categoryName, text)}
                validation={{
                    msg: t(
                        'validation.inputIsTooShort',
                        'Input is too short',
                    ),
                    isValid: text => text.length > 2,
                }} />
            <Typography sx={{ mt: 2 }} variant='h5'>
                {t('menu.searchItems', 'Search items')}sdsd
            </Typography>
            <ItemsView data={data}
                onDelete={(a) => removeItem(categoryName, a)}
                onItemClick={i => clicked(i)}
                childData={childData}
                showChildLength
                activeTab={activeItem} />

            <ItemPricingConfig activeItem={activeItem} items={data} category={categoryName}/>
        </div>
    );
}

export default connect(null, {
    addUserCategoryItem,
    removeUserCategoryItem,
})(SearchConfigItemSection);
