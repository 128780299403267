import { ExpenseRecord } from '../../../types/ExpenseRecord';
import { deleteUserExpenseRecord, saveUserExpenseRecord, updateUserExpenseRecord } from '../../../api/expenseRecord.js';

const LOAD_USER_EXPENSE_RECORDS = 'LOAD_USER_EXPENSE_RECORDS';
const ADD_USER_EXPENSE_RECORD = 'ADD_USER_EXPENSE_RECORD';
const REMOVE_USER_EXPENSE_RECORD = 'REMOVE_USER_EXPENSE_RECORD';
const UPDATE_EXPENSE_RECORDS = 'UPDATE_EXPENSE_RECORDS';

// Selector
export const userExpenseRecordsSelector =
    (state: ExpenseRecordsReducerRootState): ExpenseRecord[] => {
        return state.savingsReducers.expenseRecordReducer;
    };

// Action Creators
export function loadUserExpenseRecords(payload) {
    return {
        type: LOAD_USER_EXPENSE_RECORDS,
        payload: payload,
    };
}

export function addUserExpenseRecord(recordToAdd) {
    return {
        type: ADD_USER_EXPENSE_RECORD,
        payload: recordToAdd,
    };
}

export function addUserExpenseRecordWithApi(recordToAdd: ExpenseRecord) {
    return function(dispatch) {
        saveUserExpenseRecord(recordToAdd)
            .then((response) => {
                dispatch(addUserExpenseRecord(response.data));
            });
    };
}

export function updateExpenseRecord(records) {
    return {
        type: UPDATE_EXPENSE_RECORDS,
        payload: records,
    };
}

export function updateExpenseRecordsWithApi(record:ExpenseRecord) {
    return function(dispatch) {
        updateUserExpenseRecord(record)
            .then((response) => {
                dispatch(updateExpenseRecord(response.data));
            });
    };
}

export function removeUserExpenseRecord(recordToRemove) {
    return {
        type: REMOVE_USER_EXPENSE_RECORD,
        payload: recordToRemove,
    };
}

export function removeUserExpenseRecordWithApi(recordToRemove) {
    return function(dispatch) {
        deleteUserExpenseRecord(recordToRemove.id)
            .then(() => {
                dispatch(removeUserExpenseRecord(recordToRemove));
            });
    };

}

// Reducer
const expenseRecordReducer = (state: ExpenseRecord[] = [], { type, payload }) => {
    switch (type) {
    case LOAD_USER_EXPENSE_RECORDS:
        return payload;
    case ADD_USER_EXPENSE_RECORD:
        return [...state, payload];
    case REMOVE_USER_EXPENSE_RECORD:
        return state.filter(
            (record) => record.id !== payload.id,
        );
    case UPDATE_EXPENSE_RECORDS:
        return state.map((record) => {
            if (record.id === payload.id) {
                return payload;
            }
            return record;
        });
    default:
        return state;
    }
};

export default expenseRecordReducer;

export type ExpenseRecordsReducerRootState = ReturnType<typeof expenseRecordReducer>;
