import * as React from 'react';
import './index.css';
import { Provider } from 'react-redux';
import App from './components/App';
import reducers from './redux/reducers';
import './translation/i18n';
import './consoleErrorCaptor';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import { reducer } from './pages/savings';
import { configureStore } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import { onCLS, onINP, onLCP } from 'web-vitals';

export const store = configureStore({
    reducer: {
        ...reducers,
        savingsReducers: reducer,
    },
});

document.addEventListener('touchmove', (event) => {
    // @ts-ignore
    if (event.scale !== 1) {
        event.preventDefault();
    }
}, { passive: false });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <GlobalErrorBoundary>
            <React.Suspense fallback=''>
                <Provider store={store}>
                    <App store={store} />
                </Provider>
            </React.Suspense>
        </GlobalErrorBoundary>
    </React.StrictMode>,
);

if (process.env.NODE_ENV !== 'production') {
    onCLS(console.log);
    onINP(console.log);
    onLCP(console.log);
}

