import { connect } from 'react-redux';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import translate from '../../translation/Translator';
import SavingsSummary from './components/summary/SavingsSummary';
import ExpenseRecordsView from './components/expenseRecords/ExpenseRecordsView.tsx';
import { Navigate } from "react-router-dom";

function SavingsPage({ isSignedIn }) {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const translatedText = translate('undefined', 'Track your savings');

    useEffect(() => {
        if (isSignedIn !== undefined && isSignedIn === false) {
            setShouldRedirect(true);
        }
    }, [isSignedIn]);
    return !shouldRedirect ? (
            <div className='SavingsPage'>
                {/* <h1>{translate('savings.savingsOverview', 'Savings overview')}</h1> */}

                <Typography variant='h4' component='h1' align='center' gutterBottom>
                    {/* {translate('savings.savingsTracker', 'Savings Tracker')} */}
                    {translatedText}
                </Typography>

                <SavingsSummary />

                <ExpenseRecordsView />
            </div>)
        : (<Navigate to='/' />);
}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
    userId: state.authReducer.userId,
});

// @ts-ignore
export default connect(mapStateToProps)(SavingsPage);