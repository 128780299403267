import React, { useEffect, useState } from 'react';
import './SearchOptionsPart.scss';
import { connect } from 'react-redux';
import { SHOPS } from '../../../constants';
import translate from '../../../translation/Translator';
import { toggleAllShops } from '../../../redux/reducers/searchShopsReducer';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import { H2 } from '../../CommonStyles';
import ShopFilterButton from './ShopFilterButton';


const ShopFilterPart = function({
    excludedShops,
    toggleAllShops,
}) {
    const [isSwitchActive, setIsSwitchActive] = useState(excludedShops.length === 0);

    return (
        <div className='shopFilter'>
            <h2 style={H2}>{translate('home.shopFilter', 'Shop filter:')}</h2>
            <div className='shopFilterBtnWrapper'>
                <Checkbox checked={isSwitchActive}
                          onChange={(e) => {
                              setIsSwitchActive(e.target.checked);
                              toggleAllShops(e.target.checked);
                          }}
                          size={'large'} />

                <ButtonGroup
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
                    size='small' variant='outlined' aria-label='outlined button group'>
                    {
                        SHOPS.map(shop =>
                            <ShopFilterButton
                                key={shop}
                                excludedShops={excludedShops}
                                shop={shop} />)

                    }
                </ButtonGroup>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => ({
    excludedShops: state.searchShopsReducer.excludedShops,
});

export default connect(mapStateToProps, { toggleAllShops })(ShopFilterPart);
