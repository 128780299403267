import { Product } from '../../types/Product';

const now = new Date();
const nowMinusDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toLocaleDateString('lt');
const nowPlusDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).toLocaleDateString('lt');

export default class ProductsDataMock {
    getProducts(): Product[] {
        return [
            {
                add_date: '2022-08-19',
                id: 'Oksemedaljon,tykstegs-ellerpebkvickly',
                title: 'Oksemedaljon, tykstegs- eller peberbøf af Fritgående Kødkvæg',
                description: 'Kg-pris 197,14.',
                image: '/mock/colaImage.jpeg',
                price: '69',
                shop: 'kvickly',
                validFrom: nowMinusDay,
                validTo: nowPlusDay,
            },
            {
                'id': '23e44d1b-3497-4eb0-a968-808ba68c2ddd',
                'title': 'Coca Cola, Coca Cola Zero, Fanta eller Fanta Exotic',
                'shop': 'Lidl',
                'add_date': '2023-11-18',
                'category': 'Sodavand',
                'image': '/mock/colaImage.jpeg',
                'price': 49,
                'validFrom': nowMinusDay,
                'validTo': nowPlusDay,
                'volumePrice': 10.89,
                'volumeUnit': 'Liter',
                'searchItem': 'cola',
            },
            {
                'id': 'f15a4643-8d06-418a-9397-33f6e1e68e74',
                'title': 'Appelsiner',
                'shop': 'Lidl',
                'add_date': '2023-11-18',
                'category': 'Frugt',
                'image': '/mock/orangesImage.jpeg',
                'price': 20,
                'validFrom': nowMinusDay,
                'validTo': nowPlusDay,
                'volumePrice': 10,
                'volumeUnit': 'Kg',
                'searchItem': 'appelsin',
            },

        ];
    }

}

