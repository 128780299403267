export const API_URL = process.env.REACT_APP_DOCKERIZED && process.env.NODE_ENV !=='development'
    ? '/api'
    : 'http://localhost:8080/api';
export const API_BASE_URL = process.env.REACT_APP_DOCKERIZED
    ? '/'
    : 'http://localhost:8080/';

export const USER = '/user';
export const USER_ADD = '/user/new';
export const USER_CATEGORIES = '/user/categories';
export const USER_SEARCH = '/user/search';
export const CART_BIND = '/cart/bind';
export const CART_BIND_REJECT = '/cart/bind/reject';
export const CART_BIND_APPROVE = '/cart/bind/approve';
export const USER_CATEGORIES_ITEM = '/user/category/';
export const USER_CART_ITEM = '/cart/item';
export const USER_CART_ITEMS = '/cart/item/all';
export const USER_LOGOUT = '/user/logout';
export const CATEGORY_ITEMS = '/categories/';
