import React from 'react';
import './ShoppingList.scss';
import { useSelector } from 'react-redux';
import { Product } from '../../../types/Product';
import ListItem from './ShoppingListItem/ListItem';
import AddExpenseDialog from '../../../pages/cart/components/AddExpenseDialog';
import { UserCategoryWithItems } from '../../../types/UserCategoryWithItems';
import { UserCategoriesReducerRootState } from '../../../redux/reducers/userCategoriesReducer';
import SavingsSummary from '../../../pages/savings/components/summary/SavingsSummary';

function ShoppingList({ shoppingItems }: { shoppingItems: Product[] }) {
    const [dialogProductIndex, setDialogProductIndex] = React.useState(null);

    const categories: UserCategoryWithItems = useSelector(
        (state: UserCategoriesReducerRootState) => state.userCategoriesReducer,
    );

    function openExpenseDialog(index) {
        setDialogProductIndex(index);
    }

    function closeExpenseDialog() {
        setDialogProductIndex(-1);
    }

    function resolveProductSearchItem(product: Product) {
        if (product.searchItem) return product.searchItem;

        //TODO: potential bug here finding first
        return Object.values(categories).flat()
            .find((category: string) => {
                return product.title.toLowerCase().includes(category.toLowerCase());
            });
    }

    const shoppingItem = shoppingItems[dialogProductIndex];
    return (
        <div className='shopping-list'>
            {shoppingItems.map((product, index) => (
                <ListItem itemNumber={index}
                    key={product.id}
                    product={product}
                    openExpenseDialog={openExpenseDialog} />
            ))}

            {shoppingItem && (
                <AddExpenseDialog
                    closeExpenseDialog={closeExpenseDialog}
                    searchItem={resolveProductSearchItem(shoppingItem)}
                    product={shoppingItem}
                    categories={categories}
                />)
            }

        </div>

    );
}

export default ShoppingList;
