import React, { useState } from 'react';
import { Button, FormControl, NativeSelect, TableCell, TableRow, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import translate from '../../../../translation/Translator';
import { ExpenseRecord } from '../../../../types/ExpenseRecord';
import { calculateSavingsForExpenseRecord, dateToString } from '../../../../util/util';
import { useSelector } from 'react-redux';
import { UserCategoryWithItems } from '../../../../types/UserCategoryWithItems';
import { replaceNonCharsWithEmpty } from '../../../../util/inputUtil';

const PURCHASE_PRICE_FIELD = 'purchasePrice';
const PURCHASE_VOLUME_FIELD = 'purchaseVolume';
const REGULAR_PRICE_FIELD = 'regularPrice';

function ExpenseRecordsTable_NewRecordRow({ handleNewRecordAdd, setIsAddingNewProduct }) {
    const OTHER_TRANSLATED_TEXT = translate('undefined', 'other');
    // @ts-ignore
    const categories = useSelector((state): UserCategoryWithItems => state.userCategoriesReducer);
    const initialRecord = {
        category: OTHER_TRANSLATED_TEXT,
        categoryItem: OTHER_TRANSLATED_TEXT,
        regularPrice: null,
        purchasePrice: null,
        purchaseVolume: null,
        createdAt: dateToString(new Date()),
    };
    // @ts-ignore id missing
    const [newProduct, setNewProduct] = useState<ExpenseRecord>(initialRecord);
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const [category, setCategory] = useState<string>(OTHER_TRANSLATED_TEXT);
    const [item, setItem] = useState<string>(OTHER_TRANSLATED_TEXT);

    const handleEditProductField = (event, field) => {
        const newValue = event.target.value;

        const changedProduct = { ...newProduct, [field]: replaceNonCharsWithEmpty(newValue) };

        setNewProduct(changedProduct);
    };


    function calculateSavings(product: ExpenseRecord) {
        const result = calculateSavingsForExpenseRecord(product);
        if (result > 0) {
            return result;
        }

        return 0;
    }


    function onSaveEditClick() {
        const errors = [];
        if (!newProduct.regularPrice || newProduct.regularPrice <= 0) {
            errors.push(REGULAR_PRICE_FIELD);
        }

        if (!newProduct.purchasePrice || newProduct.purchasePrice <= 0) {
            errors.push(PURCHASE_PRICE_FIELD);
        }

        if (!newProduct.purchaseVolume || newProduct.purchaseVolume <= 0) {
            errors.push(PURCHASE_VOLUME_FIELD);
        }

        if (errors.length > 0) {
            setErrorFields(errors);
            return;
        }

        setErrorFields([]);
        handleNewRecordAdd(newProduct);
    }

    function onClearClick() {
        setIsAddingNewProduct(false);
    }

    function handleDeleteExpenseClick() {
        setIsAddingNewProduct(false);
    }

    function handleCategoryChange(e) {
        const category = e.target.value;
        setCategory(category);
        setItem(OTHER_TRANSLATED_TEXT);
        setNewProduct({ ...newProduct, category: category, categoryItem: OTHER_TRANSLATED_TEXT });
    }
    function handleItemChange(e) {
        const item = e.target.value;
        setItem(item);
        setNewProduct({ ...newProduct, category: category, categoryItem: item });
    }

    return (<TableRow>
        <TableCell>
            <Button>
                <CheckIcon onClick={() => onSaveEditClick()} />
            </Button>
            <Button>
                <ClearIcon onClick={() => onClearClick()} />
            </Button>
        </TableCell>
        <TableCell>
            <FormControl fullWidth>
                <NativeSelect
                    value={category}
                    onChange={(e) =>handleCategoryChange(e)}
                >
                    <option value={OTHER_TRANSLATED_TEXT}>{OTHER_TRANSLATED_TEXT}</option>
                    {Object.keys(categories).map((category) => {
                        return <option key={category} value={category}>{category}</option>;
                    })}
                </NativeSelect>
            </FormControl>
        </TableCell>
        <TableCell>
        <FormControl fullWidth>
            <NativeSelect
                value={item}
                onChange={(e) =>handleItemChange(e)}
            >
                <option value={OTHER_TRANSLATED_TEXT}>{OTHER_TRANSLATED_TEXT}</option>
                {categories[category]?.map((item) => {
                    return <option key={item} value={item}>{item}</option>;
                })}
            </NativeSelect>
            </FormControl>
        </TableCell>
        <TableCell>
            <TextField
                error={errorFields.includes(REGULAR_PRICE_FIELD)}
                value={newProduct.regularPrice || ''}
                onChange={(event) =>
                    handleEditProductField(event, REGULAR_PRICE_FIELD)
                }
                inputProps={{ min: '1' }}
            />
        </TableCell>
        <TableCell>
            <TextField
                error={errorFields.includes(PURCHASE_PRICE_FIELD)}
                value={newProduct.purchasePrice || ''}
                onChange={(event) =>
                    handleEditProductField(event, PURCHASE_PRICE_FIELD)
                }
                inputProps={{ min: '1' }}
            />
        </TableCell>
        <TableCell>
            <TextField
                error={errorFields.includes(PURCHASE_VOLUME_FIELD)}
                value={newProduct.purchaseVolume || ''}
                onChange={(event) =>
                    handleEditProductField(event, PURCHASE_VOLUME_FIELD)
                }
                inputProps={{ min: '1' }}
            />
        </TableCell>

        <TableCell>
            <Typography>
                {Math.ceil(calculateSavings(newProduct))}
            </Typography>
        </TableCell>

        <TableCell>
            <Typography>
                {newProduct.createdAt}
            </Typography>
        </TableCell>
        <TableCell>
            <Button>
                <DeleteIcon onClick={() => handleDeleteExpenseClick()} />
            </Button>
        </TableCell>

    </TableRow>);
}

export default ExpenseRecordsTable_NewRecordRow;
