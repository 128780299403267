import { actionTypes } from './itemExclusionsActionTypes';

export const loadUserItemSearchExclusions = (exclusions) => ({
    type: actionTypes.LOAD_USER_ITEM_SEARCH_EXCLUSIONS,
    payload: exclusions,
});

export const addUserItemSearchExclusions = (category, exclusionToAdd) => ({
    type: actionTypes.ADD_ITEM_SEARCH_EXCLUSION,
    payload: { category, exclusionToAdd },
});
export const deleteUserItemSearchExclusions = (item, exclusionToDelete) => ({
    type: actionTypes.DELETE_ITEM_SEARCH_EXCLUSION,
    payload: { item, exclusionToDelete },
});


const itemSearchExclusionsActions = {
    load: loadUserItemSearchExclusions,
    add: addUserItemSearchExclusions,
    delete: deleteUserItemSearchExclusions,
};
export default itemSearchExclusionsActions;