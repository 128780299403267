import React from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GoogleAuth from '../GoogleAuth';
import GlobalErrorBoundary from '../../GlobalErrorBoundary';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import translate from '../../translation/Translator';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { IS_PRODUCTION } from '../../constants';
import SavingsIcon from '@mui/icons-material/Savings';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction='down' in={!trigger}>
            {children}
        </Slide>
    );
}

function Navbar({ isSignedIn }) {
    const shouldDisplayText = useMediaQuery('(min-width:600px)');
    const navHomeText = translate('nav.home', 'home');
    const navSavingsText = translate('nav.savings', 'savings');
    const navCartText = translate('nav.cart', 'cart');
    const navMenuText = translate('nav.menu', 'menu');
    const ICON_WIDTH_SMALL_SCREEN = !shouldDisplayText ? { width: '2em', height: '2em' } : {};
    return (
        <GlobalErrorBoundary fallback={<p>Something went wrong</p>}>
            <div className='navbar'>
                <HideOnScroll>
                    <AppBar>
                        <Container maxWidth='xl'>
                            <Toolbar variant='dense' disableGutters>
                                <Box
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Link to='/'>
                                        <SearchIcon sx={ICON_WIDTH_SMALL_SCREEN} />
                                        {shouldDisplayText && navHomeText}
                                    </Link>
                                    <Link to='/list'>
                                        <ShoppingBasketIcon sx={ICON_WIDTH_SMALL_SCREEN} />
                                        {shouldDisplayText && navCartText}
                                    </Link>
                                    {(isSignedIn || !IS_PRODUCTION) &&
                                        <>
                                            <Link to='/savings'>
                                                <SavingsIcon sx={ICON_WIDTH_SMALL_SCREEN} />
                                                {shouldDisplayText && navSavingsText}
                                            </Link>

                                            <Link to='/menu'>
                                                <MenuIcon sx={ICON_WIDTH_SMALL_SCREEN} />
                                                {shouldDisplayText && navMenuText}
                                            </Link>
                                        </>
                                    }
                                    <GoogleAuth shouldDisplayText={shouldDisplayText}
                                                iconDisplaySxProp={ICON_WIDTH_SMALL_SCREEN} />
                                </Box>

                            </Toolbar>
                        </Container>
                    </AppBar>
                </HideOnScroll>
            </div>
        </GlobalErrorBoundary>
    );
}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps)(Navbar);
