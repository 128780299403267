import { actionTypes } from '../actions/actionTypes';

function createNewStateArray(state, payload) {
    return [...state[payload.category]].filter((el) => el !== payload.sources);
}

export const productSelectorReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case actionTypes.PRODUCT_SELECT:
            if (payload.category === 'all') {
                return { ...state, ...payload.sources };
            }

            if (payload.sources instanceof Array) {
                return {
                    ...state,
                    [payload.category]: payload.sources,
                };
            }
            return {
                ...state,
                [payload.category]: [...(state[payload.category] || []), payload.sources],
            };
        case actionTypes.PRODUCT_DESELECT:
            if (payload.category === 'all') {
                return {};
            }
            if (payload.sources instanceof Array) {
                return { ...state, [payload.category]: [] };
            }

            return {
                ...state,
                [payload.category]: createNewStateArray(state, payload),
            };
        default:
            return state;
    }
};
