import React, { useEffect, useState } from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import './ExpenseRecordsTable.scss';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
    addUserExpenseRecordWithApi,
    removeUserExpenseRecordWithApi,
    updateExpenseRecordsWithApi,
    userExpenseRecordsSelector,
} from '../../redux/expenseRecordReducer';
import { connect, useSelector } from 'react-redux';
import { ExpenseRecord } from '../../../../types/ExpenseRecord';
import translate from '../../../../translation/Translator';
import { calculateSavingsForExpenseRecord } from '../../../../util/util';
import ExpenseRecordsTable_NewRecordRow from './ExpenseRecordsTable_NewRecordRow';
import { replaceNonCharsWithEmpty } from '../../../../util/inputUtil';

const PURCHASE_PRICE_FIELD = 'purchasePrice';
const PURCHASE_VOLUME_FIELD = 'purchaseVolume';
const REGULAR_PRICE_FIELD = 'regularPrice';

function ExpenseRecordsTable({
    updateExpenseRecordsWithApi,
    addUserExpenseRecordWithApi,
    removeUserExpenseRecordWithApi,
}) {
    const records = useSelector(userExpenseRecordsSelector);
    const [expenseRecords, setExpenseRecords] = useState(records);
    const [editableFieldNumber, setEditableFieldNumber] = useState(null);
    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
    const theme = useTheme();
    const shouldBeDenseTable = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setExpenseRecords(records);
    }, [records]);

    const handleEditProductField = (event, product, field, index) => {
        const copy = [...expenseRecords];
        copy[index] = { ...product, [field]: replaceNonCharsWithEmpty(event.target.value) };
        setExpenseRecords(copy);
    };

    function calculateSavings(product: ExpenseRecord) {
        const result = calculateSavingsForExpenseRecord(product);
        if (result > 0) {
            return result;
        }
        return 0;
    }

    function onSaveEditClick(index) {
        setEditableFieldNumber(null);
        const existingRecord = expenseRecords[index];
        const updatedRecord = {
            id: existingRecord.id,
            category: existingRecord.category,
            categoryItem: existingRecord.categoryItem,
            regularPrice: existingRecord.regularPrice,
            purchasePrice: existingRecord.purchasePrice,
            purchaseVolume: existingRecord.purchaseVolume,
            createdAt: existingRecord.createdAt,
        };
        updateExpenseRecordsWithApi(updatedRecord);
    }

    function handleNewRecordAdd(record) {
        setIsAddingNewProduct(false);
        addUserExpenseRecordWithApi(record);
    }

    function onClearClick() {
        setEditableFieldNumber(null);
        setExpenseRecords([...records]);
    }

    function onDeleteExpenseClick(index) {
        setEditableFieldNumber(null);
        removeUserExpenseRecordWithApi(expenseRecords[index]);
    }

    function onEditProductClick(index) {
        setEditableFieldNumber(index);
    }


    function getEditOptionsCell(isEditableRow, index) {
        if (isEditableRow) {
            return (<>
                <Button>
                    <CheckIcon onClick={() => onSaveEditClick(index)} />
                </Button>
                <Button>
                    <ClearIcon onClick={() => onClearClick()} />
                </Button>
            </>);
        }

        return (
            <Button onClick={() => onEditProductClick(index)}>
                <EditIcon />
            </Button>
        );
    }

    function getCategoryCell(product) {
        return <Typography>
            {product.category}
        </Typography>;
    }

    function getItemCell(product) {
        return <Typography>
            {product.categoryItem}
        </Typography>;
    }

    function getRegularPriceCell(isEditableRow: boolean, product: ExpenseRecord, index: number) {
        if (isEditableRow) {
            return (<TextField
                value={product.regularPrice}
                onChange={(event) =>
                    handleEditProductField(event, product, REGULAR_PRICE_FIELD, index)
                }
                inputProps={{ min: '0' }}
            />);
        }

        return <Typography>
            {product.regularPrice}
        </Typography>;
    }

    function getPurchasePriceCell(isEditableRow: boolean, product: ExpenseRecord, index: number) {
        if (isEditableRow) {
            return <TextField
                value={product.purchasePrice}
                onChange={(event) =>
                    handleEditProductField(event, product, PURCHASE_PRICE_FIELD, index)
                }
                inputProps={{ min: '0' }}
            />;
        }

        return <Typography>
            {product.purchasePrice}
        </Typography>;
    }

    function getPurchaseVolumeCell(isEditableRow, product, index) {
        if (isEditableRow) {
            return <TextField
                value={product.purchaseVolume}
                onChange={(event) =>
                    handleEditProductField(event, product, PURCHASE_VOLUME_FIELD, index)
                }
                inputProps={{ min: '0' }}
            />;
        }

        return <Typography>
            {product.purchaseVolume}
        </Typography>;
    }

    function getSavingsCell(product) {
        return <Typography>
            {Math.ceil(calculateSavings(product))}
        </Typography>;
    }

    function getAddDateCell(product) {
        return <Typography>
            {product.createdAt}
        </Typography>;
    }

    function getDeleteCell(index) {
        return <Button>
            <DeleteIcon onClick={() => onDeleteExpenseClick(index)} />
        </Button>;
    }

    function onNewRecordAddClick() {
        setIsAddingNewProduct(!isAddingNewProduct);
    }

    return (<TableContainer component={Paper}>
            <Table className={'expenseRecordsTable'} size={shouldBeDenseTable ? 'small' : 'medium'}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Button onClick={() => onNewRecordAddClick()}>
                                <AddIcon fontSize='large' />
                            </Button>
                        </TableCell>
                        <TableCell>{translate('undefined', 'Category')}</TableCell>
                        <TableCell>{translate('undefined', 'Item')}</TableCell>
                        <TableCell>{translate('undefined', 'Regular Price(1000 g/ml)')}</TableCell>
                        <TableCell>{translate('undefined', 'Purchase Price')}</TableCell>
                        <TableCell>{translate('undefined', 'Purchase Volume (g/ml)')}</TableCell>
                        <TableCell>{translate('undefined', 'Savings')}</TableCell>
                        <TableCell>{translate('undefined', 'Add date')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isAddingNewProduct &&
                        <ExpenseRecordsTable_NewRecordRow
                            handleNewRecordAdd={handleNewRecordAdd}
                            setIsAddingNewProduct={setIsAddingNewProduct} />
                    }

                    {
                        expenseRecords.map((product, index) => {
                            const isEditableRow = editableFieldNumber === index;
                            return (<TableRow key={index}>
                                <TableCell>
                                    {getEditOptionsCell(isEditableRow, index)}
                                </TableCell>
                                <TableCell>
                                    {getCategoryCell(product)}
                                </TableCell>
                                <TableCell>
                                    {getItemCell(product)}
                                </TableCell>
                                <TableCell>
                                    {getRegularPriceCell(isEditableRow, product, index)}
                                </TableCell>
                                <TableCell>
                                    {getPurchasePriceCell(isEditableRow, product, index)}
                                </TableCell>
                                <TableCell>
                                    {getPurchaseVolumeCell(isEditableRow, product, index)}
                                </TableCell>
                                <TableCell>
                                    {getSavingsCell(product)}
                                </TableCell>
                                <TableCell>
                                    {getAddDateCell(product)}
                                </TableCell>
                                <TableCell>
                                    {getDeleteCell(index)}
                                </TableCell>
                            </TableRow>);
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default connect(null,
    { addUserExpenseRecordWithApi, updateExpenseRecordsWithApi, removeUserExpenseRecordWithApi })
(ExpenseRecordsTable);