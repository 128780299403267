
export const isNumberInput = (input: any) => {
    const newValue = parseInt(input);
    const replaced  = input.replace(/[^0-9]/g, '');

    return !Number.isNaN(newValue);
};
export const replaceNonCharsWithEmpty = (input: string) => {
    return input.replace(/[^0-9]/g, '');
};
