import { actionTypes } from '../actions/actionTypes';
import { MAX_NOTES_PER_CATEGORY } from '../../constants';

export const userNotesReducer = (state = {}, { type, payload }) => {
    // state e.g: {kylling: ['as','a']}
    switch (type) {
        case actionTypes.LOAD_USER_NOTES:
            if (payload == null) {
                return state;
            }
            return payload;
        case actionTypes.UPDATE_CATEGORY_NOTES:
            const { notes } = payload;
            return { ...state, [payload.category]: notes };
        case actionTypes.ADD_NOTE_TO_CATEGORY:
            const { note } = payload;
            const categoryNotes = [...(state[payload.category] || [])];

            if (
                !categoryNotes.includes(note) &&
            categoryNotes.length <= MAX_NOTES_PER_CATEGORY
            ) {
                return { ...state, [payload.category]: [note, ...categoryNotes] };
            }
            return { ...state };
        case actionTypes.DELETE_NOTE_FROM_CATEGORY:
            const { index } = payload;
            const filteredNotes = state[payload.category].filter(
                (item, i) => i !== index
            );

            return { ...state, [payload.category]: filteredNotes };

            // case actionTypes.ADD_USER_CATEGORY:
            //    userCategories = { ...state };
            //    if (payload.length > 2) userCategories[payload.toLowerCase()] = [];
            //    return userCategories;
            // case actionTypes.REMOVE_USER_CATEGORY:
            //    userCategories = { ...state };
            //    delete userCategories[payload];
            //    return userCategories;
            // case actionTypes.ADD_NOTE_TO_CATEGORY:
            //    userCategories = { ...state };
            //    if (payload.length > 2) userCategories[payload.toLowerCase()] = [];
            //    return userCategories;
        default:
            return state;
    }
};
