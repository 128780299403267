import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import Navbar from './Navbar/Navbar';
import CartPage from '../pages/cart/CartPage';
import MenuPage from '../pages/menu/MenuPage';
import NotFound from '../pages/notFound/NotFound';
import SavingsPage from '../pages/savings/SavingsPage.tsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import React from 'react';

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: blueGrey[400],
            },
            secondary: {
                main: '#11cb5f',
            },
        },
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <div className='App'>
                    <Navbar />
                    {/* <Navbar /> */}
                    <div style={{ 'marginTop': '60px' }}></div>
                    <Routes>
                        <Route
                            path='/'
                            exact
                            element={<HomePage />}
                        />
                        <Route path='/list' exact element={<CartPage />} />
                        <Route path='/menu' exact element={<MenuPage />} />
                        <Route path='/savings' exact element={<SavingsPage />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
