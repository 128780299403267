import { actionTypes } from '../actions/actionTypes';
import { CART_PRODUCTS, IS_PRODUCTION } from '../../constants';
import { filterOutOfDateProducts } from '../../util/util';
import { addMultipleItemsToCart } from '../../api/user';

function saveStateToStorage(state) {
    localStorage.setItem(CART_PRODUCTS, JSON.stringify(state));
}

function initState() {
    const cartPayload = localStorage.getItem(CART_PRODUCTS);
    if (cartPayload) {
        try {
            const currentState = { ...JSON.parse(cartPayload) };
            currentState.products = filterOutOfDateProducts(currentState.products);
            saveStateToStorage(currentState);
            return currentState;
        } catch (e) {
            if (!IS_PRODUCTION) {
                console.log(e);
            }
            localStorage.removeItem(CART_PRODUCTS);
        }
    }
    return { cartId: null, products: [] };
}

function productAlreadyExist(state, payload) {
    return state.products.find(
        (item) =>
            item.title === payload.product.title &&
            item.shop === payload.product.shop,
    );
}

function productIsNotInPayload(payload, localProduct) {
    return !payload.products.find((p) => p.id === localProduct.id);
}

export const cartReducer = (state = initState(), { type, payload }) => {
    let result;
    switch (type) {
        case actionTypes.ADD_PRODUCT_TO_SHOPPING_BASKET:
            if (productAlreadyExist(state, payload)) {
                return state;
            }
            result = [...state.products, payload.product];
            saveStateToStorage(result);
            return { ...state, products: result };
        case actionTypes.REMOVE_PRODUCT_FROM_SHOPPING_BASKET:
            result = state.products.filter((p) => p.id !== payload.product.id);
            saveStateToStorage(result);
            return { ...state, products: result };
        case actionTypes.LOAD_USER_CART:
            if (payload) {
                const notSavedProducts = [];
                state.products.forEach((localProduct) => {
                // push if product not exist in db
                    if (productIsNotInPayload(payload, localProduct)) {
                        payload.products.push(localProduct);
                        notSavedProducts.push(localProduct.id);
                    }
                });
                if (notSavedProducts.length > 0) {
                    addMultipleItemsToCart(payload.cartId, notSavedProducts);
                }
                saveStateToStorage(payload);
                return payload;
            }
            return { cartId: null, products: state.products };
        default:
            return state;
    }
};
