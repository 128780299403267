import { CategoryPriceNotes } from '../../../types/CategoryPriceNotes';
import { saveUserProductPriceNotes } from '../../../api/user.js';

const LOAD_USER_PRODUCT_PRICE_NOTES = 'LOAD_USER_PRODUCT_PRICE_NOTES';
const ADD_PRODUCT_PRODUCT_PRICE_NOTE = 'ADD_PRODUCT_PRODUCT_PRICE_NOTE';
// const REMOVE_PRODUCT_PRODUCT_PRICE_NOTE = 'REMOVE_PRODUCT_PRODUCT_PRICE_NOTE';

export interface AddProductPriceNote {
    category: string;
    itemName: string;
    regularPrice: number;
}

// Selector
export const productPriceNotesSelector = (state: SavingsProductsReducerRootState) => {
    // @ts-ignore
    return state.savingsReducers.productPriceNotesReducer;
};


// Action Creators
export function loadProductPriceNotes(notes) {
    return {
        type: LOAD_USER_PRODUCT_PRICE_NOTES,
        payload: notes,
    };
}

export function addProductPriceNotes(productPriceNote: AddProductPriceNote): {
    type: string,
    payload: AddProductPriceNote
} {
    return {
        type: ADD_PRODUCT_PRODUCT_PRICE_NOTE,
        payload: productPriceNote,
    };
}

export function addProductPriceNotesWithApi(productPriceNote: AddProductPriceNote) {
    return function(dispatch, getState) {
        const allPriceNotes: CategoryPriceNotes = getState()
            .savingsReducers
            .productPriceNotesReducer;

        const newCategoryObject = {
            [productPriceNote.itemName]: { 'regularPrice': productPriceNote.regularPrice },
        };

        const result = {
            ...allPriceNotes,
            [productPriceNote.category]: newCategoryObject,
        };

        saveUserProductPriceNotes(result)
            .then(() => {
                dispatch(dispatch(loadProductPriceNotes(result)));
            });
    };

}

const productPriceNotesReducer = (
    state: CategoryPriceNotes = {},
    { type, payload }: { type: string, payload: AddProductPriceNote },
) => {
    switch (type) {
    case LOAD_USER_PRODUCT_PRICE_NOTES:
        return payload || state;
    case ADD_PRODUCT_PRODUCT_PRICE_NOTE:
        const newCategoryObject = {
            ...state[payload.category] || {},  // Fallback to empty object
            [payload.itemName]: { 'regularPrice': payload.regularPrice },
        };

        return {
            ...state,
            [payload.category]: newCategoryObject,
        };

    default:
        return state;
    }
};

export default productPriceNotesReducer;

export type SavingsProductsReducerRootState = ReturnType<typeof productPriceNotesReducer>;
