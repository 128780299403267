import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchUserDataAction, googleSignIn, googleSignOut } from '../redux/actions/index';
import { IS_PRODUCTION, TOKEN_STORAGE } from '../constants';
import { getUserToken } from '../api/user';
import Button from '@mui/material/Button';
import { jwtDecode } from 'jwt-decode';
import LogoutIcon from '@mui/icons-material/Logout';

const USER_STORAGE = 'user';

function GoogleAuth({
    isSignedIn,
    shouldDisplayText,
    iconDisplaySxProp,
    googleSignIn,
    googleSignOut: signOut,
    fetchUserDataAction: fetchUserData,
}) {

    const divRef = useRef(null);
    const initialized = useRef(false);
    const buttonShown = useRef(false);
    useEffect(() => {

        const user = JSON.parse(getStorageUser());
        if (user) {
            googleSignIn(user.id);
            if (!IS_PRODUCTION) {
                console.log('Dummy sign in');
                dummySignIn(user);
            }
        } else {
            if (IS_PRODUCTION) {
                if (!initialized.current) {
                    initialized.current = true;
                    window.google.accounts.id.initialize({
                        client_id: '157014852583-cod3d0bqts31r4qtciljl67rr7bodsv6.apps.googleusercontent.com',
                        callback: (credentialResponse) => {
                            onAuthChange(jwtDecode(credentialResponse.credential), divRef);
                        },
                    });
                }

                if (divRef.current && !buttonShown.current) {
                    buttonShown.current = true;
                    window.google.accounts.id.renderButton(
                        divRef.current,
                        { theme: 'outline', size: 'large', type: 'icon', shape: 'circle' },
                    );

                    window.google.accounts.id.prompt();
                }
            }
            if (!IS_PRODUCTION) {
                console.log('Dummy sign in');
                dummySignIn();
            }

        }
    }, [divRef.current]);


    async function onAuthChange(credentials) {

        if (credentials) {
            const user = parseUserDataFromGoogle(credentials);
            googleSignIn(user.id);
            await getUserToken(user);
            fetchUserData();
            sessionStorage.setItem(USER_STORAGE, JSON.stringify(user));
        } else {
            sessionStorage.removeItem(USER_STORAGE);
            sessionStorage.removeItem(TOKEN_STORAGE);
            signOut();
            buttonShown.current = false;
            window.google.accounts.id.renderButton(
                divRef.current,
                { theme: 'outline', size: 'large', type: 'icon', shape: 'circle' },
            );
        }
    }

    async function dummySignIn(providedUser) {
        const user = providedUser || { 'id': 1234, email: 'email@gmail.com' };
        onAuthChange(user);
    }

    function getStorageUser() {
        return sessionStorage.getItem(USER_STORAGE);
    }

    function onSignOutClick() {
        onAuthChange();
    }

    function parseUserDataFromGoogle(credentials) {
        return {
            id: credentials.sub,
            email: credentials.email,
        };
    }


    if (isSignedIn) {
        return (
            <Button
                size='small'
                sx={{ color: 'white', display: 'block' }}
                type='button'
                className='btn-g-login'
                onClick={onSignOutClick}
            >
                <LogoutIcon sx={iconDisplaySxProp} />
                {shouldDisplayText && 'Logout'}
            </Button>
        );
    }

    return (
        <Button
            size='small'
            type='button'
            className='btn-g-login'
        >
            <div ref={divRef}></div>
        </Button>
    );

}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
    // isSignedIn: false,
});

export default connect(mapStateToProps, {
    googleSignIn,
    googleSignOut,
    fetchUserDataAction,
})(GoogleAuth);
