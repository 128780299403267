import React, { useEffect, useState } from 'react';
import './SearchOptionsPart.scss';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProductCategory from '../ProductCategory/ProductCategory';
import Tabs from '../../Tabs/Tabs';
import { deSelectProductsAction, selectProductsAction } from '../../../redux/actions';
import { CATEGORIES_AND_PRODUCTS, LT_CATEGORIES_AND_PRODUCTS } from '../../../constants';
import ShopFilterPart from './ShopFilterPart';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { primaryColor } from '../../CommonStyles';

function createTabs(categoriesAndProducts, isEditable) {
    const keys = Object.keys(categoriesAndProducts);

    return keys.map((key, i) => (
        <div
            label={key}
            key={key}
            index={i}
            products={
                categoriesAndProducts[key].items || categoriesAndProducts[key] // TODO: this should be only items
            }
        >
            <ProductCategory
                editable={isEditable}
                key={key}
                category={key}
                products={
                    categoriesAndProducts[key].items || categoriesAndProducts[key] // TODO: this should be only items
                }
                index={i}
            />
        </div>
    ));
}

function renderCategoriesListSwitch(
    isSignedIn,
    isUserCategoriesActive = false,
    setIsUserCategories,
    translator,
) {
    return isSignedIn ? (
        <div className='categoriesSwitch'>
            <div>
                <label htmlFor='other'>
                    <input
                        style={{accentColor: primaryColor}}
                        type='radio'
                        checked={isUserCategoriesActive}
                        readOnly
                        onChange={() => setIsUserCategories(!isUserCategoriesActive)}
                    />
                    {translator('home.userList', 'USER LIST')}
                </label>
            </div>
            <div>
                <label htmlFor='list'>
                    <input
                        type='radio'
                        id='list'
                        name='default'
                        value={isUserCategoriesActive}
                        readOnly
                        checked={!isUserCategoriesActive}
                        onClick={() => setIsUserCategories(!isUserCategoriesActive)}
                    />
                    {translator('home.defaultList', 'DEFAULT LIST')}
                </label>
            </div>
        </div>
    ) : (
        ''
    );
}

const SearchOptionsPart = function({
    categories,
    selectProductsAction,
    deSelectProductsAction,
    editable,
    isSignedIn,
}) {
    const [isUserCategories, setIsUserCategories] = useState(isSignedIn || false);
    const { t } = useTranslation();
    useEffect(() => {
        const keys = Object.keys(categories);
        if (isSignedIn || isSignedIn === undefined) {
            if (keys.length > 0) {
                setIsUserCategories(true);
            } else {
                setIsUserCategories(false);
            }
        } else {
            setIsUserCategories(false);
        }
    }, [isSignedIn, categories]);
    const resolvePreDefinedCategories = () =>
        sessionStorage.getItem('i18nextLng') === 'en'
            ? CATEGORIES_AND_PRODUCTS
            : LT_CATEGORIES_AND_PRODUCTS;
    const categoriesToRender = isUserCategories
        ? categories
        : resolvePreDefinedCategories();

    return (
        <div className='searchOptions'>
            {!editable && (
                <div>
                    <h2
                        className='margin-centered'>
                        {t(
                            'home.chooseProductToSearch',
                            'Choose products to search:',
                        )}
                    </h2>
                    {renderCategoriesListSwitch(
                        isSignedIn,
                        isUserCategories,
                        () => {},
                        t,
                    )}

                    <ButtonGroup variant='outlined' aria-label='outlined button group'>
                        <Button
                            type='button'
                            className='all-select-btn'
                            onClick={() => selectProductsAction('all', categoriesToRender)}
                        >
                            {t('home.selectAll', 'Select all')}
                        </Button>
                        <Button
                            type='button'
                            className='all-select-btn'
                            onClick={() => deSelectProductsAction('all', categoriesToRender)}
                        >
                            {t('home.deselectAll', 'Deselect all')}
                        </Button>
                    </ButtonGroup>

                </div>)
            }


            <Tabs isEditable={editable}>
                {createTabs(categoriesToRender, editable)}
            </Tabs>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
    categories: state.userCategoriesReducer,
});

export default connect(mapStateToProps, {
    selectProductsAction,
    deSelectProductsAction,
})(SearchOptionsPart);
