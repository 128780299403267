import React, { useState } from 'react';
import './ListItem.scss';
import { connect, useSelector } from 'react-redux';
import { removeProductFromBasketAction } from '../../../../redux/actions';
import { ProductImage } from '../../../ProductImage/ProductImage';
import { setColourBasedOnDateDates } from '../../../../util/util';
import translate from '../../../../translation/Translator';
import { Product } from '../../../../types/Product';
import { UserCategoriesReducerRootState } from '../../../../redux/reducers/userCategoriesReducer';
import { UserCategoryWithItems } from '../../../../types/UserCategoryWithItems';
import Tooltip from '@mui/material/Tooltip';
import SavingsIcon from '@mui/icons-material/Savings';
import Button from '@mui/material/Button';

export interface ListItemProps {
    product: Product,
    cartId: string,
    removeProductFromBasketAction: (any: string, product) => void,
    openExpenseDialog: (index: number) => void,
    itemNumber: number
}

function ListItem(props: ListItemProps) {
    const [isSelected, setIsSelected] = useState(false);
    const { product }: { product: Product } = props;
    const { openExpenseDialog, itemNumber } = props;
    const { removeProductFromBasketAction: removeFromBasket, cartId } = props;
    const categories: UserCategoryWithItems = useSelector(
        (state: UserCategoriesReducerRootState) => state.userCategoriesReducer,
    );

    function toggleIsSelected() {
        setIsSelected(!isSelected);
    }

    function renderPricingSection({ price, extraData, volumePrice, volumeUnit }: Product) {
        return (
            <div className='price_part'>
                <h3>
                    <span>{price ? `${price} ${translate('currency', 'dkk')}` : ''}</span>
                    {extraData ? (
                        <>
                            <span className='oldPrice'>{extraData.oldPrice}</span>
                            <span className='discountPercent'>
                                {extraData.discountPercent
                                    ? `(${extraData.discountPercent})`
                                    : ''}
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </h3>
                {volumePrice && volumeUnit ?
                    (<h3>{volumePrice} {translate('currency', 'dkk')}/{volumeUnit}{' '}</h3>) :
                    ('')}
            </div>
        );
    }

    return (<div>
        <div
            className={`shopping-item ${isSelected && 'item-selected'}`}
            onClick={toggleIsSelected}>
            <ProductImage wrapperClass='' product={product} />

            <h2 className='listItemTitle'>
                {product.title}
                {product.description && <h3>{product.description}</h3>}
            </h2>
            <h3 style={setColourBasedOnDateDates(product.validFrom, product.validTo)}>
                {product.validFrom} {translate('product.to', 'to')}{' '}
                {product.validTo ? product.validTo : 'XX'}
            </h3>

            <Tooltip title={translate('savings.recordExpenses', 'Record expenses')}
                     placement='top-start'
                     followCursor>
                <Button className='add-expense-dialog-btn'
                        type='button'
                        onClick={() => openExpenseDialog(itemNumber)}>
                    <SavingsIcon fontSize='large' />
                </Button>
            </Tooltip>

            {renderPricingSection(product)}

            <button
                className='remove_from_cart_btn'
                type='button'
                onClick={(e) => {
                    e.stopPropagation();
                    removeFromBasket(cartId, product);
                }}>X
            </button>
        </div>
    </div>
    );
}

const mapStateToProps = (state) => ({
    cartId: state.cartReducer.cartId,
});
export default connect(mapStateToProps, { removeProductFromBasketAction })(ListItem);
