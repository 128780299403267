import React, { useEffect, useState } from 'react';
import './Product.scss';
import { connect } from 'react-redux';
import { addProductToBasketAction, removeProductFromBasketAction } from '../../redux/actions';
import { setColourBasedOnDateDates } from '../../util/util';
import { ProductImage } from '../ProductImage/ProductImage';
import translate from '../../translation/Translator';

function Product({
    productData,
    cart,
    searchItem,
    addProductToBasketAction,
    removeProductFromBasketAction,
}) {
    const [isSelected, setSelected] = useState(false);
    useEffect(() => {
        setSelected(isItemSelected());
    }, []);

    function isItemSelected() {
        return cart.products.find(
            (selectedItem) =>
                productData.title === selectedItem.title &&
                productData.shop === selectedItem.shop,
        );
    }

    function toggleBasketItem(cartId) {
        setSelected(!isSelected);
        if (!isSelected) {
            addProductToBasketAction(cartId, {...productData, searchItem});
        } else {
            removeProductFromBasketAction(cartId, productData);
        }
    }

    function renderCategoryOrDescription() {
        const { category } = productData;
        return category && (
            <h3>Cat:
                <button type='button' className='categoryBtn'>
                    {productData.category}
                </button>
            </h3>
        );
    }

    function renderPricingSection({ price, meta, volumePrice, volumeUnit }) {
        return (
            <>
                <h3>
                    <span>{price ? `${price} ${translate('currency', 'dkk')}` : ''}</span>
                    {meta && (
                        <>
                            <span className='oldPrice'>{meta.oldPrice}</span>
                            <span className='discountPercent'>
                                {meta.discountPercent && `(${meta.discountPercent})`}
                            </span>
                        </>
                    )}
                </h3>
                {volumePrice && volumeUnit && (
                    <h3>
                        {volumePrice} {translate('currency', 'dkk')}/{volumeUnit}{' '}
                    </h3>
                )}
            </>
        );
    }

    function addImageFromCount(count) {
        const result = [];
        for (let i = 0; i < count; i++) {
            result.push(
                <img src={`${productData.shop}-x-icon.png`} alt='shop size icon' />,
            );
        }
        return result;
    }

    function renderShopMetaData() {
        return (
            <div className='shopMetaData'>
                <h5>{productData.shop}</h5>
                {productData.discountCardRequired && (
                    <img
                        src={`${productData.shop}-card.png`}
                        alt='shop card icon'
                        height='30px'
                        width='30px'
                    />
                )}
                {productData.meta &&
                productData.meta.starCount != null &&
                addImageFromCount(productData.meta.starCount)}
            </div>
        );
    }

    return (
        <div className={`product ${isItemSelected() ? 'selected-product' : ''}`}>
            <h2>{productData.title}</h2>
            <ProductImage product={productData} />
            {renderCategoryOrDescription()}
            {productData.description ? <h3>{productData.description}</h3> : ''}
            {renderPricingSection(productData)}
            <h4
                style={setColourBasedOnDateDates(
                    productData.validFrom,
                    productData.validTo,
                )}
            >
                {productData.validFrom} {translate('product.to', 'to')}{' '}
                {productData.validTo ? productData.validTo : 'XX'}
            </h4>
            {renderShopMetaData()}
            <button
                type='button'
                className='addToBaskedBtn'
                onClick={() => toggleBasketItem(cart.cartId)}
            >
                {translate('product.addToBasket', 'Add to basket')}
            </button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
});

export default connect(mapStateToProps, {
    removeProductFromBasketAction,
    addProductToBasketAction,
})(Product);
