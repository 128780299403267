import React from 'react';
import { sendErrorLogToServer } from './api/log';

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        sendErrorLogToServer(error, errorInfo);
    }

    render() {
        const { state, props } = this;
        if (state.hasError) {
            // You can render any custom fallback UI
            return props.fallback;
        }

        return props.children;
    }
}

export default GlobalErrorBoundary;