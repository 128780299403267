import React from 'react';
import { Box, Typography } from '@mui/material';
import translate from '../../../../translation/Translator';

function SavingsSummary_AggregateView({ amount }) {

    const days = 5;
    return (
        <Box sx={{textAlign: 'start', display:'flex' }}>
            <Typography variant='body1'>{translate('undefined', 'Totally saved amount:')}</Typography>
            <Typography variant='body1'>{Math.ceil(amount)} DKK</Typography>
            {/* <Typography */}
            {/*     variant='caption'>{translate('undefined', 'Last X days').replace('X', days.toString())}</Typography> */}
        </Box>
    );
}

export default SavingsSummary_AggregateView;
