import React from 'react';
import { connect } from 'react-redux';
import {
    addNoteToCategoryAction,
    deleteUserCategoryNoteAction,
    filterProductCategoryAction,
    updateUserCategoryNoteAction,
} from '../../../redux/actions';
import './CheapestView.scss';
import CheapestViewItem from './CheapestViewItem';
import plusIcon from '../../../assets/plus.svg';

function CheapestView({
    notes,
    category,
    updateUserCategoryNote,
    deleteUserCategoryNote,
    addNoteToCategory,
}) {
    function deleteItemHandler(index) {
        deleteUserCategoryNote(category, index);
    }

    function newNoteHandler() {
        const existingEmptyNoteIndex = notes.find((note) => note.text === '');
        if (!existingEmptyNoteIndex) {
            addNoteToCategory(category, '');
        }
    }

    function saveNote(previousNote, text) {
        updateUserCategoryNote(category, previousNote, text);
    }

    return (
        <div className="cheapest_view">
            <div className="button-plus-wrapper">
                <button
                    className="button-plus"
                    onClick={newNoteHandler}
                    type="button"
                >
                    <img src={plusIcon} alt="plus icon" />
                </button>
            </div>

            {notes.map((note, index) => (
                <CheapestViewItem
                    /* eslint-disable-next-line react/jsx-no-bind */
                    saveNote={saveNote}
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onDeleteClick={deleteItemHandler}
                    idx={index}
                    note={note}
                    key={note}
                />
            ))}
        </div>
    );
}

const mapStateToProps = (state) => ({
    products: state.productReducer.products,
    isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps, {
    filterProductCategoryAction,
    updateUserCategoryNote: updateUserCategoryNoteAction,
    deleteUserCategoryNote: deleteUserCategoryNoteAction,
    addNoteToCategory: addNoteToCategoryAction,
})(CheapestView);
