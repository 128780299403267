import { UserCategoryWithItems } from '../../types/UserCategoryWithItems';
import { Cart } from '../../types/Cart';
import { CategoryPriceNotes } from '../../types/CategoryPriceNotes';
import { CategoryTextNotes } from '../../types/CategoryTextNotes';
import { SearchItemExclusions } from '../../types/SearchItemExclusions';
import { ExpenseRecord } from '../../types/ExpenseRecord.ts';
import { dateToString } from '../../util/util';

const now = new Date();
const nowMinusDay = dateToString(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1));
const nowPlusDay = dateToString(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1));

export default class UserDataApiMock {

    getCategories(): UserCategoryWithItems {
        return {
            'meat': [
                'kylling',
                'svinekød',
                'bøf',
                'grise',
                'hot wings',
                'underlår',
                'hälsans',
            ],
            'fish': [
                'laks',
                'torsk',
                'tun',
                'jomfru',
                'rejer',
            ],
            'drinks': [
                'innocent',
                'rigtig',
                'god morgen',
                'mælk',
                'cola',
                'danon',
                'pickwick',
                'cirkel',
                'laktosefri',
            ],
            'food': [
                'æg',
                'pasta',
                'fraiche',
                'müsli',
                'pølser',
                'morgen',
            ],
            'nuts': [
                'mandler',
                'hassel',
                'pistaci',
                'nødder',
            ],
            'household': [
                'papir',
                'fairy',
                'vask',
                'svamp',
                'libr',
                'alway',
                'pand',
                'mund',
                'affald',
                'duck',
                'toilet',
                'køkken',
            ],
            'fruits': [
                'æbler',
                'blåbær',
                'bananer',
                'ananas',
                'mandarin',
                'clementin',
                'appelsin',
                'kirsebær',
                'blomme',
                'fersken',
                'figen',
                'granat',
                'hindbær',
                'melon',
                'vandmelon',
                'jordbær',
                'kiwi',
                'paærer',
                'nektarin',
                'pomelo',
                'litchi',
                'druer',
            ],
            'candies': [
                'marabou',
                'Änglamark øko chokolade',
                'cooki',
                'kinder',
            ],
            'vegetables': [
                'tomat',
                'agurk',
                'majs',
                'kartofler',
                'spinat',
                'avocado',
                'citron',
                'hvidløg',
                'blomkål',
                'broccoli',
                'gulerød',
            ],
        };
    }

    getCart(): Cart {
        return {
            'cartId': '63d5960321a8efd149aeb925',
            'products': [
                {
                    'id': '23e44d1b-3497-4eb0-a968-808ba68c2ddd',
                    'title': 'Coca Cola, Coca Cola Zero, Fanta eller Fanta Exotic',
                    'shop': 'Lidl',
                    'add_date': '2023-11-18',
                    'category': 'Sodavand',
                    'image': '/mock/colaImage.jpeg',
                    'price': 49,
                    'validFrom': nowMinusDay,
                    'validTo': nowPlusDay,
                    'volumePrice': 10.89,
                    'volumeUnit': 'Liter',
                    'searchItem': 'cola',
                },
                {
                    'id': 'f15a4643-8d06-418a-9397-33f6e1e68e74',
                    'title': 'Appelsiner',
                    'shop': 'Lidl',
                    'add_date': '2023-11-18',
                    'category': 'Frugt',
                    'image': '/mock/orangesImage.jpeg',
                    'price': 20,
                    'validFrom': nowMinusDay,
                    'validTo': nowPlusDay,
                    'volumePrice': 10,
                    'volumeUnit': 'Kg',
                    'searchItem': 'appelsin',
                },
            ],
        };
    }

    getCategoryTextNotes(): CategoryTextNotes {
        return {
            'kylling': [
                'underlar 30/kg',
                'breast 55/kg',
            ],
            'tun': [
                'West: 86/kg',
                'Elvira: 87.72 /kg',
            ],
            'æbler': [
                '6,7/kg',
            ],
            'danon': [
                '10/pk',
            ],
            'libr': [
                '2.5/stk',
            ],
            'alway': [
                'Always:2,06 stk',
            ],
            'cirkel': [
                '39 price 120/kg',
            ],
            'mund': [
                'Paste:166dkk/L',
                'Lidl 17.5/l',
            ],
            'Morgen': [
                'Cruesli 25: 55/kg',
            ],
            'papir': [
                'Toilet 27/kg',
            ],
            'pasta': [
                '16/kg',
            ],
            'marabou': [
                '100/kg',
            ],
            'tomat': [
                'Ketchup beauvai25/kg',
            ],
            'vask': [
                'liquid 1.56/wash',
                'caps 2.3/wash',
            ],
            'appelsin': [
                '7.5/kg',
            ],
            'kiwi': [
                '15/kg',
            ],
            'jordbær': [
                '40/kg',
            ],
            'druer': [
                '10/kg',
            ],
            'laks': [
                '130/kg',
            ],
            'avocado': [
                '6/stk',
            ],
            'agurk': [
                '5/stk',
            ],
            'bøf': [
                '80/kg marinated',
            ],
            'hot wings': [
                '40dkk/kg',
            ],
            'blåbær': [
                'c: 60/kg u: 80/kg',
            ],
            'æg': [
                '1/stk',
            ],
            'rejer': [
                '163 /kg',
            ],
            'fairy': [
                '28/l',
            ],
            'hindbær': [
                '80/kg',
            ],
            'duck': [
                '13.33 /l',
            ],
            'affald': [
                'sk: 0.33 pk:5',
            ],
            'hälsans': [
                '156/kg',
            ],
            'jomfru': [
                '69/l',
            ],
            'svamp': [
                '3.4 pr 10',
            ],
            'pølser': [
                '44dkk/kg',
            ],
            'cola': [
                '11/l small bottles',
            ],
            'kartofler': [
                '6.7kg',
            ],
            'morgen': [
                '64/kg',
            ],
            'cooki': [
                '62/kg',
            ],
            'blomme': [
                '12/kg',
            ],
            'bananer': [
                '2/stk',
            ],
            'nektarin': [
                '10/kg',
            ],
            'gulerød': [
                '8/kg c:5/kg',
            ],
            'toilet': [
                '10/stk',
            ],
        };
    }

    getItemSearchExclusions(): SearchItemExclusions {
        return {
            'kylling': [
                'hel',
                'Frilandskylling',
            ],
            'tun': [],
            'æg': [
                'pålæg',
                'maling',
                'vægt',
                'Trusseindlæg',
                'isbæger',
                'Vægur',
            ],
            'marabou': [
                'rulle',
                'cookies',
            ],
        };
    }

    getBindingRequest() {
        return null;
    }

    getExpenseRecords(): ExpenseRecord[] {
        return [
            {
                id:"12",
                categoryItem: 'coke',
                category: 'drinks',
                regularPrice: 200,
                purchasePrice: 100,
                purchaseVolume: 1000,
                createdAt: dateToString(new Date()),
            },
            {
                id:"12",
                categoryItem: 'cola',
                category: 'drinks',
                regularPrice: 200,
                purchasePrice: 190,
                purchaseVolume: 250,
                createdAt: dateToString(new Date()),
            },
        ];
    }

    getProductPriceNotes(): CategoryPriceNotes {
        return {
            'meat': {
                'kylling':
                    {
                        'regularPrice': 20310,
                    },
            },
            'drinks': {
                'cola':
                    {
                        'regularPrice': 200,
                    },
            },
        };
    }

}