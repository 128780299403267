import React from 'react';
import './ProductList.scss';
import ScrollContainer from 'react-indiana-drag-scroll';
import { connect } from 'react-redux';
import CheapestView from './CheapestView';
import Product from '../../Product/Product';

function ProductList({ productType, products, notes, isSignedIn }) {
    function renderProductsIfPresent(productList) {
        return (
            productList &&
         // TODO: LOOK INTO THIS FILTERING AND STUFF
         productList.map((p) =>
             <Product key={p.id} productData={p} searchItem={productType} />
         )
        );
    }

    return (
        <div className='productSection'>
            <div>
                <div className='product-section-header'>
                    <h2>{productType}</h2>
                    <div className='product-section-header-options'>
                        {isSignedIn && (
                            <CheapestView category={productType} notes={notes} />
                        )}
                    </div>
                </div>
            </div>
            <ScrollContainer hideScrollbars={false} className='scroll-container'>
                <div className='productRow'>
                    {renderProductsIfPresent(products)}
                </div>
            </ScrollContainer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps)(ProductList);
