import { useTranslation } from 'react-i18next';

export default function translate(pointer:string, defaultValue:string){
    const { t } = useTranslation();

    return t(pointer, defaultValue)  ;
}



