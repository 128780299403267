import axios from 'axios';
import { API_URL } from './apiConstants';
import { getRequestConfigWithAuthToken } from './util';

export function saveUserExpenseRecord(expenseRecord) {
    return axios.post(
        API_URL + '/expenseRecord/add',
        expenseRecord,
        getRequestConfigWithAuthToken('post'),
    );
}

export function deleteUserExpenseRecord(expenseRecordId) {
    return axios.delete(
        API_URL + `/expenseRecord/${expenseRecordId}`,
        getRequestConfigWithAuthToken('delete'),
    );
}

export function updateUserExpenseRecord(expenseRecord) {
    console.log('asdf', expenseRecord);
    const expenseRecordId = expenseRecord.id;
    return axios.post(
        API_URL + `/expenseRecord/${expenseRecordId}/update`,
        { expenseRecord },
        getRequestConfigWithAuthToken('post'),
    );
}

